
import basic_api from "@/repositories/basic_api.js";

var departementMap = null
const facteursObjets = [{
  nomFacteur: "Contraintes économiques",
  lienAncre: "monetaire",
  image: "BASIC_data-1.svg",
  indicateurContenu: "indicateurEco.archieml",
  indicateurDefault: "Médiane du revenu disponible par unité de consommation"
},

{
  nomFacteur: "Isolement social",
  lienAncre: "socio-eco",
  image: "BASIC_data-1.svg",
  indicateurContenu: "indicateurSocio.archieml",
  indicateurDefault: "Agrégation des différents indicateurs"
},

{
  nomFacteur: "Environnement alimentaire appauvri",
  lienAncre: "mobilite",
  image: "BASIC_data-1.svg",
  indicateurContenu: "indicateurMobilitaire.archieml",
  indicateurDefault: "Agrégation des différents indicateurs"
},

{
  nomFacteur: "Information alimentaire insuffisante",
  lienAncre: "litteracie",
  image: "BASIC_data-1.svg",
  indicateurContenu: "indicateurLitteracie.archieml",
  indicateurDefault: "Agrégation des différents indicateurs"
},

{
  nomFacteur: "Approche combinée",
  lienAncre: "synthétique",
  image: "BASIC_data-1.svg",
  indicateurContenu: "indicateurAgrege.archieml",
  indicateurDefault: "Indicateur agregé"
}]

const saveDepartementMap = (mapping) => {
  departementMap = mapping
}

async function getDepartementMap() {
  if (departementMap == null) {
    var response = await basic_api.searchMatchingTerritoires("D", "departement");
    departementMap = new Map(
      response.map((item) => {
        return [item.code_territoire.slice(3), item.localeName];
      })
    );
  }
  console.log("returning departementMap", departementMap)
  return departementMap
}
function retournerObjetparlienAncre(lienAncre) {
  return facteursObjets.filter(function (facteursObjets) { return facteursObjets.lienAncre == lienAncre })
}
function retournerObjetparnom(nomFacteur) {
  return facteursObjets.filter(function (facteursObjets) { return facteursObjets.nomFacteur == nomFacteur })
}

function retournerFacteursObjets() {
  return facteursObjets
}

export default {
  getDepartementMap,
  saveDepartementMap,
  retournerObjetparlienAncre,
  retournerFacteursObjets,
  retournerObjetparnom,
  facteursObjets
}

