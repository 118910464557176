<template>
  <div :class="{ 'plein-ecran': estPleinEcran }">
    <div class="bouton-haut" id="bouton-plein-ecran" @click="estPleinEcran = !estPleinEcran">
      <span v-if="!estPleinEcran">passer en plein écran</span>
      <span v-if="estPleinEcran">quitter le mode plein écran</span>
    </div>

    <div class="colonnes" id="en-tete">
      <div class="colonne" id="titre">

      </div>
      <!-- <HeaderAriege v-if="displayed_project.includes('Ariege')"></HeaderAriege>
      <DefaultHeader v-else></DefaultHeader> -->
    </div>


    <div class="colonnes">
      
      <div class="colonne" id="colonne-navigation">

        <img
          class="logo"
          src="../../assets/BASIC_logo.svg"
          alt="logo du BASIC"
        />
        <h1>
          Système d’Information Sur la Transition Alimentaire
        </h1>


        <ul id="navigation">
          <li
            v-for="menu_item in menu"
            v-bind:key="menu_item.name"
            :class="{
              niveau1: true,
              visible: menu_item.visible
            }"
          >
            <span class="element_de_liste"  @click="menu_item.visible = !menu_item.visible">
              {{ menu_item.name }}
              <span class="fleche">⪢</span>
            </span>

            <ul class="liste-niveau-2">
              <li
                v-for="menu_item2 in menu_item.menu"
                v-bind:key="menu_item2.name"
                class="niveau2"
              >
                <a
                  v-if="'rapportBI' in menu_item2"
                  v-bind:class="{
                    'element_de_liste': true,
                    'niveau2': true,
                    'selected': selected_report == menu_item2.rapportBI.id + menu_item2.rapportBI.page
                  }"
                  @click="tab_clicked(menu_item2)"
                >
                  {{ menu_item2.name }}
                </a>
                <p v-if="!('rapportBI' in menu_item2) && menu_item2.name != ''" class="element_de_liste">
                  {{ menu_item2.name }}
                  <span v-if="'explication' in menu_item2" class="explication">
                    ?
                    <span class="infobulle">{{ menu_item2.explication }}</span>
                  </span>
                </p>
                <ul>
                  <li
                    v-for="menu_item3 in menu_item2.menu"
                    v-bind:key="menu_item3.name"
                    @click="tab_clicked(menu_item3)"
                    class="niveau3"
                  >

                    <a
                      v-if="'rapportBI' in menu_item3"
                      v-bind:class="{
                        element_de_liste: true,
                        niveau3: true,
                        selected:
                          selected_report ==
                          menu_item3.rapportBI.id + menu_item3.rapportBI.page,
                      }"
                    >
                      {{ menu_item3.name }}
                    </a>
                    <p v-else class="element_de_liste">{{ menu_item3.name }}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="colonne" id="colonne-contenu">
        <div
          class="colonnes"
          v-if="choix_territoires != null"
          id="choix-des-territoires"
        >
          <div class="colonne" id="territoire-principal">
            <h2>Choix du territoire</h2>
            <Multiselect
              :searchable="true"
              :options="choix_territoires.territoires_principaux"
              v-model="territoires_selected"
              mode="tags"
            ></Multiselect>
          </div>
          <div class="colonne" id="territoire-comparaison">
            <h2>Territoire de comparaison</h2>
            <Multiselect
              :searchable="true"
              :options="choix_territoires.territoires_comparaison"
              v-model="comparison_selected"
              mode="tags"
            ></Multiselect>
          </div>
        </div>
        <div v-else>
          Chargement des territoires
          <CoucheChargement></CoucheChargement>
        </div>
        <div v-if="loading_step != null">
          <p>{{ loading_step }}</p>
          <CoucheChargement></CoucheChargement>
        </div>
        <PowerbiContainer v-if="rapportBISelected != null" :rapportBI="rapportBISelected" :territoires_principaux="territoires_principaux" :territoires_de_comparaison="territoires_de_comparaison" :powerbi_report_list="powerbi_report_list"></PowerbiContainer>
      </div>
    </div>
  </div>
</template>


<script>
import Multiselect from '@vueform/multiselect'
import basic_api from "@/repositories/basic_api.js";
import configuration from "@/utils/menu_configuration.js";
import menu_utils from "@/utils/menu_utils.js";
// import HeaderAriege from "@/components/headers/HeaderAriege.vue";
// import DefaultHeader from "@/components/headers/DefaultHeader.vue";
import PowerbiContainer from "@/components/widgets/PowerbiContainer.vue";
import CoucheChargement from "@/components/headers/CoucheChargement.vue";

export default {
  name: "TerritoiresGeneriques",
  components: {
    Multiselect,
    // HeaderAriege,
    // DefaultHeader,
    PowerbiContainer,
    CoucheChargement
},
  data() {
    return {
      selected_report: "",
      menu: {},
      loading_step: null,
      territoires_selected: [],
      comparison_selected: [],
      choix_territoires: null,
      estPleinEcran: false,
      displayed_project: "",
      rapportBISelected: null,
      territoires_de_comparaison: [],
      territoires_principaux: []
    };
  },
  watch: {
    territoires_selected: async function (newValue, oldValue) {
      console.log("territoires_selected changed:", oldValue, "=>", newValue);
      if (this.territoires_selected.length > 0) {
        var selection = [...this.territoires_selected];
      } else {
        selection = [...this.choix_territoires.territoires_principaux];
      }
      this.territoires_principaux = selection
    },
    comparison_selected: async function (newValue, oldValue) {
      console.log(oldValue, "=>", newValue);
      if (this.comparison_selected.length > 0) {
        var selection = [...this.comparison_selected];
      } else {
        selection = [...this.choix_territoires.territoires_comparaison];
      }
      this.territoires_de_comparaison = selection
    },
  },
  beforeMount: async function () {
    this.menu = configuration.menuConfiguration;
    // Récupération de la liste des territoires disponibles dans les selecteurs
    if (this.$route.params.project != null) {
      this.displayed_project = this.$route.params.project;
      console.log("this.project is:", this.displayed_project);
      this.choix_territoires = await basic_api.getTerritoires(
        this.$route.params.project
      );
    } else {
        console.log("Pas de projet fourni dans l'URL")
    }
    if (this.choix_territoires == null) {
      this.choix_territoires = configuration.choix_territoires;
      console.log("Pas de territoires officiellement disponible, donc on rend dispo par défaut", this.choix_territoires)
    }

    if (this.displayed_project.includes("Ariege")) {
      this.menu.push({
        name: "La carte des acteurs du PAT du PETR de l’Ariège",
        visible: true,
        menu: [
          {
            name: "La carte des acteurs du PAT du PETR de l’Ariège",
            rapportBI: {
              id: "303e43d0-2ebc-4f0d-bc25-329db41b2c31",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=303e43d0-2ebc-4f0d-bc25-329db41b2c31&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSectionc8f4dc128477c4c85e4e",
            },
          },
        ],
      });
    }

    this.choix_territoires.territoires_principaux.sort()
    this.choix_territoires.territoires_comparaison.sort()
    console.log("this.choix_territoires is:", this.choix_territoires);
    // définition du territoire principal sélectionné par défaut
    this.territoires_selected.push(this.choix_territoires.principal_par_defaut);
    console.log("territoires_selected", this.territoires_selected)
    this.territoires_principaux = [...this.territoires_selected]

    // définition du territoire de comparaison sélectionné par défaut
    if (this.choix_territoires.comparaison_par_defaut) {
      this.comparison_selected.push(
        this.choix_territoires.comparaison_par_defaut
      );
    }
    else {
      this.comparison_selected.push(
        this.choix_territoires.territoires_comparaison[0]
      );
    }
    this.territoires_de_comparaison = [...this.comparison_selected]
  },
  mounted: function () {
    this.refresh_token();
  },
  computed: {
    powerbi_report_list: function () {
      var powerbi_list = {};
      for (var menu_item of this.menu) {
        this.list_report_from_menu_item(menu_item, powerbi_list);
      }
      console.log("powerbi_list", powerbi_list);
      return powerbi_list;
    },
  },
  methods: {
    compute_selector_label: function (values_selected, selector) {
      console.log(values_selected, selector)
      return values_selected.map((item) => {
        return item.label
      }).join(', ');
    },
    list_report_from_menu_item: function (menu_item, powerbi_list) {
      if ("rapportBI" in menu_item) {
        powerbi_list[menu_item.rapportBI.id + menu_item.rapportBI.page] =
          menu_item.rapportBI;
      }
      if ("menu" in menu_item) {
        for (var sub_menu_item of menu_item["menu"]) {
          this.list_report_from_menu_item(sub_menu_item, powerbi_list);
        }
      }
    },
    refresh_token: async function () {
      this.loading_step = "Connexion à Powerbi"
      var success = await basic_api.generateToken();
      console.log("get token result :", success);
      if (success) {
        this.loading_step = null
        var page_defined_in_url = this.$route.query.page
        if (page_defined_in_url){
          var default_item = menu_utils.get_menu_item_from_name(page_defined_in_url)
        }
        if (default_item == null) {
          default_item = this.menu[0].menu[0].menu[0];
        }
        this.selected_report = default_item.rapportBI.id + default_item.rapportBI.page;
        this.rapportBISelected = default_item.rapportBI
      }
      else {
        this.loading_step = "Failed to connect to PowerBI"
      }
    },
    tab_clicked: async function (menu_item) {
      console.log("tab clicked called with", menu_item);
      this.selected_report = menu_item.rapportBI.id + menu_item.rapportBI.page;
      if ("rapportBI" in menu_item) {
        // Update URL with current menu name
        var newRoute = {
          path:this.$route.path,
          query: {...this.$route.query}
        }
        newRoute.query.page = menu_item.name
        this.$router.push(newRoute);
        this.rapportBISelected = menu_item.rapportBI
      } else {
        this.tab_clicked(menu_item.menu[0]);
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  margin: 0 0rem 0 2rem;
  max-width: 700px;
  font-weight: 300;
  color: grey;
  line-height: 1.3em;
  margin-bottom: 0.7rem;
}

h2 {
  font-size: 18px;
  font-weight: 400;
  color: rgb(80, 80, 80);
}
#en-tete {
  width: 20em;
}
#titre {
  width: 700px;
  flex: none;
}
#logos {
  text-align: right;
  width: calc(100% - 700px);
  padding: 5px;
  height: 50px;
}
.logo {
  /* float: right; */
  height: 50px;
  width: 173px;
  /* display: inline; */
  /* padding-left: 2rem; */
  vertical-align: middle;
  margin: 13px 0px;
}
.logo-petit {
  height: 80%;
}
.colonnes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.colonne {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

#colonne-navigation {

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20em;

  height: 97vh;

}
#colonne-contenu {
  flex: 4;
}
#navigation {
  padding: 1rem 0rem;
  text-align: left;
  overflow: auto;
  box-shadow: 0 -1px 25px #00000014;
  margin: 1rem 2rem 0rem 2rem;
  border-radius: .5rem;
}
.liste-niveau-2 {
  max-height: 0px;
  transition: 0.3s;
  overflow: hidden;
}
.visible .liste-niveau-2 {
  max-height: 800px;
}
.element_de_liste {
  padding: 3px 1rem;
  width: 100%;
  display: inline-block;
  position: relative;
}
.fleche {
  position: absolute;
  left: calc(100% - 2rem);
  top: 3px;
  visibility: hidden;
  transition: 0.3s;
}
a.element_de_liste {
  color: inherit;
}
a.element_de_liste:not(.selected):hover {
  background-color: rgb(225, 225, 225);
}
.niveau1 {
  margin-bottom: 2rem;
}
.niveau1 > .element_de_liste {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.niveau1 > .element_de_liste:hover .fleche {
  visibility: visible;
}
.visible .fleche {
  visibility: visible;
  transform: rotate(90deg) translate(2px, 0px);
}

.niveau2 > .element_de_liste {
  /* font-size: 20px; */
  font-weight: 700;
  /* opacity: 0.6; */
  color: grey;
  text-transform: uppercase;
}
.niveau2 {
  margin-bottom: 1rem;
}
.niveau3 {
  /* padding-left: 0.5rem; */
}
.selected {
  background-color: rgb(59, 59, 59);
  color: white !important;
}
.explication {
  border-radius: 20px;
  height: 22px;
  width: 22px;
  border: 2px solid #bebebe;
  position: relative;
  /* z-index: -2; */
  display: inline-block;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: #bebebe;
  top: -2px;
  /* border-style: inset; */
  box-shadow: inset 0 0 0px #000;
  margin-left: 6px;
  cursor: default;
}
.infobulle {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5px 8px;
  text-align: left;
  text-transform: none;
  font-weight: normal;
  border-radius: 0 6px 6px 6px;
  /* left: calc(100% + 15px); */
  top: 25px;
  color: white;
}
.explication:hover .infobulle {
  visibility: visible;
}

#choix-des-territoires {
  padding: 14px 1rem 0;
}
#territoire-principal {
  flex-grow: 2.6;
}
#territoire-principal .v-select {
  max-width: 600px;
}
#territoire-comparaison {
  padding: 0 1rem;
}


.v-select {
  max-height: 2.2rem;
}

#bouton-plein-ecran {
  position: absolute;
  top: 80px;
  right: 18px;
  background-color: white;
  text-decoration: underline;
  /* color: blue; */
  padding: 9px;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  box-shadow: 0px 11px 6px #00000014;
  border-radius: 0 0 5px 5px;
}

.plein-ecran #bouton-plein-ecran {
  top: 0;
}

/* Icone de chargement */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}





</style>

<style>


.multiselect-tag{
  font-size: 1rem;
  background-color: rgb(59, 59, 59);
  font-weight: 400;
}

.bouton-haut{
  display: none;
}

</style>