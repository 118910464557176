module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Présentation du facteur de risque"
		},
		{
			"type": "text",
			"value": "Le coût plus élevé des denrées de bonne qualité nutritionnelle ou encore des produits labellisés constitue un frein à l’achat pour les ménages les moins aisés. L’alimentation est une variable est la variable principale d’ajustement du budget. On paye d’abord son loyer, ses factures, puis on ajuste le budget de l’alimentation en fonction du reste à vivre. La majorité des individus ou ménages en précarité sont confrontés à des contraintes financières (ménages monoparentaux, travailleurs pauvres, étudiants, retraités, personnes en situation administrative irrégulière, personnes sans-abris etc.)."
		},
		{
			"type": "text",
			"value": "## [En savoir plus](#en-savoir-plus)"
		},
		{
			"type": "list",
			"value": [
				"Laisney, C., (2013), Les différences sociales en matière d’alimentation, Centre d’études et de prospective, n°64, .",
				"Darmon, N. & Drewnowski, A., (2008), Does social class predict diet quality?, The American Journal of Clinical Nutrition, vol. 87, n°5, p. 1107‑1117.",
				"Darmon, N., Ferguson, E. L. & Briend, A., (2002), A Cost Constraint Alone Has Adverse Effects on Food Selection and Nutrient Density: An Analysis of Human Diets by Linear Programming, The Journal of Nutrition, vol. 132, n°12, p. 3764‑3771.",
				"Maillot, M., Darmon, N. & Drewnowski, A., (2010), Are the lowest-cost healthful food plans culturally and socially acceptable?, Public Health Nutrition, vol. 13, n°8, p. 1178‑1185.",
				"[Page de vulgarisation sur le seuil des 3,85 euros](https://www.inrae.fr/actualites/lutter-contre-inegalites-sociales-dacces-alimentation-saine-durable)"
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisées"
		},
		{
			"type": "text",
			"value": "### Médiane du revenu disponible par unité de consommation (euros)"
		},
		{
			"type": "Source",
			"value": "Insee-DGFIP-Cnaf-Cnav-CCMSA, Fichier localisé social et fiscal (Filosofi), 2020"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/mediane-du-revenu-disponible-par-uc)"
		},
		{
			"type": "text",
			"value": "## Verbatims"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« Quand mes alloc’ arrivent sur mon compte, il me reste, allez, on va dire cent euros pour que je  puisse réussir à manger, après, tout le reste qui passe, c’est du découvert »*"
		},
		{
			"type": "text",
			"value": ">*« Moi, ce mois-ci, je suis à moins 200 et je n’ai pas acheté de bouffe ! »*"
		},
		{
			"type": "text",
			"value": ">*« Il y a 5 ans, avec 50 euros, on mettait un caddie plein. Mais maintenant, je mange toujours hard discount, et avec 50 euros, c’est la moitié du caddie. »*"
		}
	]
};