<template>
  <div v-if="loaded_report">
    <div id="console">
      <div v-if="false">
        <p><b>Error :</b>{{ loaded_report.setSlicerStateError }}</p>
        <p>
          <b>slicer state :</b>
          {{ loaded_report.slicer_state }}
        </p>
        <div
          class="box"
          v-for="(filter, key) in loaded_report.slicer_filters"
          v-bind:key="key"
        >
          <p>
            <b>Filtre n°{{ key }} =></b> {{ filter }}
          </p>
        </div>
        <p>
          Le slicer ne devrait avoir qu'un seul filtre et son 'target' devrait
          ressembler à "target": { "table": "modele_tat territoires", "column":
          "Territoire" } Et il ne devrait pas limiter les valeurs possible
        </p>
        <div v-if="loaded_report">
          <p>
            OtherErrors =
            {{ loaded_report.OtherErrors }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="loaded_report && loaded_report.page_display_name">
      <p>
        Titre de la page dans Powerbi:
        <b>{{ loaded_report.page_display_name }}</b>
      </p>
      <p>
        <a
          :href="
            'https://app.powerbi.com/groups/me/reports/' +
            report_config.id +
            '/' +
            report_config.page
          "
          target="_blank"
          >Voir le rapport sur powerbi</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DebugConsole",
  components: {},
  props: {
    loaded_report: Object,
    report_config: Object,
  },
  data() {
    return {};
  },
  watch: {},
  mounted: function () {},
  methods: {},
};
</script>

<style scoped>
#console {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgb(0 0 0 / 69%);
  color: white;
  padding: 1rem;
  width: 50%;
  max-height: 100%;
  overflow: auto;
}
</style>
