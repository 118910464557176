export const evolution = (valeur1, valeur2) => {
    if (!valeur1 || !valeur2) {
        return 0
    }
    return new Intl.NumberFormat("en-US", {
        style: "percent",
        signDisplay: "exceptZero",
    }).format((valeur2 - valeur1) / valeur1);
    }

export default { evolution }
