<template>
  <div class="indicateur-chiffre-colonne">
    <div class="indicateur-titre">
      <section class="indicateur-logo-chiffre">
        <img :src="require(`@/assets/icon/${image}`)" />
        <h2>{{ this.formatter(chiffre) }}</h2>
        <div class="indicateur-tooltip column">
          <button class="tooltip-image" v-tooltip="'Source: ' + source">
            <img
              :src="require(`@/assets/icon/information.svg`)"
              alt="tooltip"
            />
          </button>
        </div>
      </section>
      <div class="indicateur-texte">{{ indicateur }}</div>
    </div>
    <div class="indicateur-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import formatter_utils from "@/utils/formatter_utils.js";

export default {
  props: {
    indicateur: String,
    chiffre: Number,
    image: String,
    description: String,
    source: String,
    format: String,
  },
  data() {
    return {
      tooltipContent: "Tooltip content",
    };
  },
  methods: {
    formatter(chiffre) {
      if (this.format == "pourcentage") {
        return formatter_utils.formatPercent(chiffre);
      } else {
        return `${formatter_utils.formatNumber(chiffre)} ${this.format}`;
      }
    },
  },
};
</script>

<style scoped>
.indicateur-chiffre-colonne {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--Brand-light, #c7dce4);
}

.indicateur-chiffre-colonne .indicateur-titre {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.indicateur-tooltip {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.indicateur-logo-chiffre {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.indicateur-logo-chiffre img {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 24px;
}

.indicateur-logo-chiffre h2 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: 0.48px;
}

.indicateur-texte {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: 0.24px;
}
.indicateur-description {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 0.2px;
}

.tooltip-image {
  border: 0px;
  padding: 0px;
  margin: 0px;
  background-color: white;
}
</style>
