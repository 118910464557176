<template>
  <div id="infographie">
    <v-charts
      :option="chartOptionsBar"
      @click="handleClick"
      autoresize
    ></v-charts>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  name: "VulnerabiliteAlimentaire",
  data() {
    return {
      chartOptionsBar: {
        color: ["#0A3E55", "#419D78", "#C97064", "#F5E2C8"],
        tooltip: {
          trigger: "item",
          formatter: "{b}<br />",
        },
        title: {
          text: "Catégories \nde facteurs de risque \nde précarité alimentaire",
          left: "center",
          top: "center",
          fontSize: 30,
        },
        series: [
          {
            type: "pie",
            startAngle: 162,
            radius: ["40%", "65%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            edgeDistance: "0%",
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              fontSize: 14,
              position: "outer",
              align: "center",
            },
            labelLayout: {
              hideOverlap: true,
              align: "center",
            },

            labelLine: {
              show: false,
              verticalAlign: "top",
              align: "center",
              dx: -50,
            },
            emphasis: {
              title: {
                show: false,
                fontSize: 25,
                fontWeight: "bold",
              },
            },
            data: [
              { value: 40, name: "Contraintes économiques" },
              { value: 20, name: "Isolement\n social" },
              { value: 20, name: "Environnement alimentaire appauvri" },
              { value: 20, name: "Information \nalimentaire \ninsuffisante" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    handleClick(param) {
      var name = param.name;
      if (name == "Contraintes économiques") {
        window.open("obsoalim/indicateurs?facteur=monetaire", "_blank");
      } else if (name == "Isolement\n social") {
        window.open("obsoalim/indicateurs?facteur=socio-eco", "_blank");
      } else if (name == "Environnement alimentaire appauvri") {
        window.open("obsoalim/indicateurs?facteur=mobilite", "_blank");
      } else if (name == "Information \nalimentaire \ninsuffisante") {
        window.open("obsoalim/indicateurs?facteur=litteracie", "_blank");
      }
    },
  },
};
</script>

<style scoped>
</style>
