import axios from "axios";

const axiosAbortControllers = {
  searchTerritoires: null,
};

const apiClient = axios.create({
  baseURL: "https://lebasic.nohost.me/api",
  //baseURL: "https://observatoire-filieres.azurewebsites.net",
  //  baseURL: "http://localhost:7071",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

var Homemade_Cache = {};

var Servers_Errors = {};

async function serverCall(url, abortController, no_cache) {
  if (!no_cache && url in Homemade_Cache) {
    return JSON.parse(JSON.stringify(Homemade_Cache[url]));
  }
  try {
    var results = await apiClient.get(url, {
      signal: abortController ? abortController.signal : null,
    });
  } catch (error) {
    if (error.code == "ERR_CANCELED") {
      console.log("serverCall canceled on url", url);
      return null;
    }
    console.log("serverCall Error", error, "on url", url);
    Servers_Errors[url] = error;
    return null;
  }
  if (url in Servers_Errors) {
    delete Servers_Errors[url];
  }
  Homemade_Cache[url] = results ? results.data : null;

  return JSON.parse(JSON.stringify(Homemade_Cache[url]));
}

async function searchMatchingTerritoires(query, type_territoire) {
  if (axiosAbortControllers.searchTerritoires) {
    axiosAbortControllers.searchTerritoires.abort();
  }
  axiosAbortControllers.searchTerritoires = new AbortController();
  var url = `/parcel/GeoData/${query}`;
  if (type_territoire) {
    url += `?type_territoire=${type_territoire}`;
  }
  var result = await serverCall(url, axiosAbortControllers.searchTerritoires);
  axiosAbortControllers.searchTerritoires = null;
  return result;
}

async function getTerritories(query, type_territoire) {
  if (axiosAbortControllers.searchTerritoires) {
    axiosAbortControllers.searchTerritoires.abort();
  }
  axiosAbortControllers.searchTerritoires = new AbortController();
  var url = `/territoire/libelle/${query}`;
  if (type_territoire) {
    url += `?niveau_detail_geographique=${type_territoire}`;
  }
  var result = await serverCall(url, axiosAbortControllers.searchTerritoires);
  console.log("result", result);
  axiosAbortControllers.searchTerritoires = null;
  return result;
}

async function getRGADataForTerritory(
  code_territoire,
  annee,
  type_dimension_rga
) {
  var url = `/RGA/${type_dimension_rga}/${code_territoire}?annee=${annee}`;
  var result = await serverCall(url);
  return result;
}

async function getRGADataparCommunePourTerritoire(code_territoire, annee) {
  var url = `/RGA_par_commune/${code_territoire}?annee=${annee}`;
  var result = await serverCall(url);
  return result;
}

async function getBasicDataForTerritory(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null
) {
  var url = `/basic/${code_territoire}`;
  if (annee && niveau_detail_geographique) {
    url += `?annee=${annee}&niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  var result = await serverCall(url);
  return result;
}

async function getDataForTerritory(
  code_territoire,
  niveau_detail_geographique = null
) {
  var url = `/territoires/${code_territoire}`;
  if (niveau_detail_geographique) {
    url += `?niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  var result = await serverCall(url);
  return result;
}

async function getGEOJSONForTerritory(code_territoire) {
  var url = `/territoire/GeoJSON/${code_territoire}`;
  var result = await serverCall(url);
  return result;
}

async function getRPGDataForTerritory(
  code_territoire,
  annee = null,
  niveau_detail_geographique = null,
  niveau_detail_culture = null
) {
  var url = `/RPG/${code_territoire}`;
  if (annee && niveau_detail_geographique) {
    url += `?annee=${annee}&niveau_detail_geographique=${niveau_detail_geographique}`;
  }
  if (annee && niveau_detail_culture) {
    url += `?annee=${annee}&niveau_detail_culture=${niveau_detail_culture}`;
  }
  var result = await serverCall(url);
  return result;
}

async function getTerritoires(project) {
  const url = `/sista/${project}`;
  return await serverCall(url);
}

var powerBiApiToken = "";

async function generateToken() {
  const url = "/sista/token";
  var data = await serverCall(url);
  if (data != null) {
    powerBiApiToken = data.access_token;
    return true;
  }
  return false;
}

function getToken() {
  return powerBiApiToken;
}

export default {
  getToken,
  generateToken,
  getTerritoires,
  searchMatchingTerritoires,
  getTerritories,
  getRGADataForTerritory,
  getGEOJSONForTerritory,
  getRPGDataForTerritory,
  getRGADataparCommunePourTerritoire,
  getBasicDataForTerritory,
  getDataForTerritory,
};
