<template>
    <div class="legende-container">
    <div class="legende">
        <div class="traits" v-for="(borne, index) in bornes" :key="borne">
               <template v-if="bornes[index + 1]">
                    <div :style="gradientStyle(borne.couleur, bornes[index + 1].couleur)" class="tete">
                    <p class="label-legende">{{ borne.borne * 100 }}</p>
                </div>
                </template>
                   <template v-else>
                    <div :style="gradientStyle(borne.couleur, '#ffffff')" class="tete">
                    <div class="label-legende">{{ borne.borne * 100 }}</div>
                    </div>
                    </template>
        </div>
    </div>
    </div>
</template>

<script>

export default {
    name: "carteLegende",
    data() {
        return {
            bornes: [
                {
                    borne: 0,
                    couleur: "#ffffff"
                },
                {
                    borne: 0.2,
                    couleur: "#edf8fb"
                },
                {
                    borne: 0.4,
                    couleur: "#edf8fb"
                },
                {
                    borne: 0.6,
                    couleur: "#b2e2e2"
                },
                {
                    borne: 0.8,
                    couleur: "#66c2a4"
                },
                {
                    borne: 1,
                    couleur: "#2ca25f"
                },
            ]
        }
    },
    methods: {
        gradientStyle(borneMin, borneMax){
        var gradientStyle =
        {
            backgroundImage: "linear-gradient(.25turn," + borneMin + "," + borneMax + ")",
            height: "5px",
            width: "100%"
        }
        console.log(gradientStyle)
        return gradientStyle
    }
    }
}
</script>

<style>

.legende-container {
    position: absolute;
    padding: 30px 50px;
    bottom: 0;
    z-index: 10000;;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;

}
.legende {
    display: flex;
    flex-direction: row;
}

.traits {
    border-right: 1px solid black;
    height: 5px;
    flex-grow: 1;
    display: flex;

}

.legende div.traits:last-child {
    border-right: none;
    background-color: none;
    width: 0px;
    flex-grow: 0;
    transform: translate(-5px, -100%);
}

.legende div.traits:first-child {
    border-left: 1px solid black;

}

.label-legende {
    display: absolute;
    transform: translate(-5%, -100%);
}
</style>
