export function creerObjetFiltre(code_pnrs, listeCommunes) {
  var listeCommunesOrdonnees = listeCommunes.sort((a, b) => {
    if (a.libelle_epci !== b.libelle_epci) {
      return a.libelle_epci.localeCompare(b.libelle_epci);
    } else {
      return a.libelle_commune.localeCompare(b.libelle_commune);
    }
  });
  var liste_filtree = listeCommunesOrdonnees.filter((communes) =>
    code_pnrs.includes(communes.code_parc)
  );
  const result = liste_filtree.reduce((acc, curr) => {
    const foundIndex = acc.findIndex(
      (item) => item.label === curr.libelle_epci
    );
    if (foundIndex !== -1) {
      acc[foundIndex].options.push(curr.libelle_commune);
    } else {
      acc.push({
        label: curr.libelle_epci,
        options: [curr.libelle_commune],
      });
    }
    return acc;
  }, []);

  return result;
}

export function getCommunesIds(communesListesChoisies, communesListes) {
  let codeTerritoireLists = communesListes
    .filter((communes) =>
      communesListesChoisies.includes(communes.libelle_commune)
    )
    .map((communeObject) => communeObject.code_territoire);
  console.log(codeTerritoireLists);
  return codeTerritoireLists;
}

export function getToutesCommunesParcs(listeParc, listeCommunes) {
  console.log(listeParc, listeCommunes);
  return listeCommunes
    .filter((communes) => listeParc.includes(communes.code_parc))
    .map((communeObject) => communeObject.code_territoire);
}

export const listeParcsUltraMarins = [
  "Parc national de la Guadeloupe",
  "Parc national de La Réunion",
  "Parc amazonien de Guyane",
  "PNR de Guyane",
  "PNR de Martinique",
];
