<template>
  <router-link
    :to="{
      name: 'departement',
      params: { code: code },
      query: { facteur: lienAncre },
    }"
  >
    <img
      style="height: 48px; padding: 12px"
      :src="require(`@/assets/icon/${image}`)"
    />
    <p class="facteur-titre">{{ nomFacteur }}</p>
  </router-link>
  <router-link
    :to="{
      name: 'indicateurs',
      query: { facteur: lienAncre },
    }"
  >
    En savoir plus
  </router-link>
</template>

<script>
export default {
  props: {
    nomFacteur: String,
    lienAncre: String,
    image: String,
    code: String,
  },
};
</script>

<style scoped>
.facteur-titre {
  height: 48px;
  color: #000;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  margin-bottom: 0px;
}
.facteur-lien {
  color: #000;
  text-align: center;

  /* Texte/petit */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
}
</style>
