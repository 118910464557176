import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "../node_modules/bulma/css/bulma.css";
import "@vueform/multiselect/themes/default.css";
import 'floating-vue/dist/style.css';
import VueEcharts from 'vue-echarts'
import { VueShowdown } from 'vue-showdown'
import FloatingVue from 'floating-vue'



const app = createApp(App)
app.use(router).use(FloatingVue).component('v-charts', VueEcharts).component('vue-showdown', VueShowdown, {
    options: {
        emoji: true,
        tables: true
    }
},)
router.isReady().then(() => app.mount('#app'))
