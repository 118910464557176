<template>
  <div class="landing container-selector obsoalim-container">
    <div class="info-recherche container">
      <VulnerabiliteAlimentaireVue />

      <div v-if="multiselectOptions" id="titre">
        <h1>{{ title }}</h1>

        <Multiselect
          v-model="selectedItem"
          placeholder="Choisissez votre département"
          :searchable="true"
          trackBy="label"
          :close-on-select="false"
          :create-option="false"
          :options="multiselectOptions"
          :loading="isLoading"
        ></Multiselect>
        <h2>{{ accroche }}</h2>
      </div>
    </div>
    <div id="footer">
      <h2>Porteurs du projet:</h2>
      <div id="footer-logo">
        <img
          :src="require(`@/assets/obsoalim/sponsors/ADM_logo.png`)"
          height="50"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/AGRO_logo.svg`)"
          height="50"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/cirad.png`)"
          height="50"
        />
        <img
          :src="
            require(`@/assets/obsoalim/sponsors/Logo-INRAE_Transparent.svg`)
          "
          height="10"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/occitanie.png`)"
          height="50"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/BASIC_logo.svg`)"
          height="50"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/Bloc_metro.png`)"
          height="50"
        />
        <img
          :src="require(`@/assets/obsoalim/sponsors/herault.png`)"
          height="50"
        />

        <img
          :src="
            require(`@/assets/obsoalim/sponsors/Préfet_de_la_région_Occitanie.svg`)
          "
          height="50"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Multiselect from "@vueform/multiselect";

import vobsalim_utils from "@/utils/vobsalim_utils.js";

import VulnerabiliteAlimentaireVue from "@/components/vobsalim/VulnérabiliteAlimentaire.vue";

export default {
  name: "VobsalimHome",
  components: {
    Multiselect,
    VulnerabiliteAlimentaireVue,
  },
  data() {
    return {
      isLoading: true,
      multiselectOptions: null,
      departementNames: null,
      title: "Diagnostic territorial de la précarité alimentaire",
      selectedItem: null,
      logos: [
        {
          nom: "Le Basic",
          image: "BASIC_logo.svg",
        },
        {
          nom: "Le Basic",
          image: "BASIC_logo.svg",
        },
      ],
    };
  },
  computed: {},
  methods: {
    handleChange() {
      if (this.selectedItem === null || this.selectedItem === "") {
        this.selectedItem = null;
        console.log("Pas de département sélectionné");
        return;
      }
      console.log("Département sélectionné:", this.selectedItem);
      this.$router.push({
        name: "departement",
        params: { code: this.selectedItem },
      });
    },
    retourTerritoires() {
      this.selectedItem = null;
    },
  },
  beforeMount() {
    this.isLoading = true;
    vobsalim_utils.getDepartementMap().then((response) => {
      this.departementNames = response;
      this.multiselectOptions = new Array();
      response.forEach((value, key) => {
        this.multiselectOptions.push({ value: key, label: value });
      });
      console.log("departementNames", this.departementNames);
      this.isLoading = false;
    });
  },
  mounted() {},
  watch: {
    selectedItem: ["handleChange"],
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://fonts.googleapis.com/css?family=Source Sans Pro");

.obsoalim-container h1 {
  font-size: 48px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 150%;
  color: #1d3d63;
}

.obsoalim-container h2 {
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  line-height: 150%;
  color: #1d3d63;
}

.logo {
  position: absolute;
}
.landing {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  font: S;
  width: 100%;
  margin: 0;
  padding: 0px 0px;
  text-align: left;
}
.container-selector {
  display: grid; /* or inline-flex */
  height: calc(100vh - 60px);
}

@media (min-width: 1200px) {
  .container-selector {
    display: grid;
    grid-template-rows: auto 120px;
    padding-top: 50px;
  }
}
@media (min-width: 1200px) {
  .info-recherche {
    display: grid;
    grid-template-columns: 4fr 3fr;
  }
}
#titre {
  margin: auto;
}
.full-subcontainer {
  grid-column-start: 1;
  grid-column-end: end;
}

.full-subcontainer li {
  flex: 1 1 0px;
}
.retour {
  /* Texte/moyen/regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  margin: auto;
  padding: 0;
  border: none;
  background: none;
  height: 24px;
  display: inline-flex;
  align-items: center;
}

#footer {
  grid-column-start: 1;
  grid-column-end: end;
  display: flex;
  flex-direction: column;
  background-color: #ebeef2;
}

#footer h2 {
  text-align: center;
}
#footer-logo {
  display: flex;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

#footer-logo img {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 130px;
}
</style>
