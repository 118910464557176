module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Présentation du facteur de risque"
		},
		{
			"type": "text",
			"value": "La précarité alimentaire est multifactorielle. Elle peut notamment être liée à des contraintes économiques, à un Isolement social, à un environnement alimentaire appauvri ou à des difficultés d’accès à de l’information nutritionnelle."
		},
		{
			"type": "text",
			"value": "L’Approche combinée permet de mettre en évidence des communes dans lesquelles plusieurs catégories de facteurs de risque se cumulent. Elle est basée sur un calcul qui prend en compte les quatre catégories précédemment citées, en donnant deux fois plus de poids à la celle des contraintes économiques, sur la base de l’hypothèse que c’est celle qui empêche le plus directement l’accès à une alimentation de qualité."
		}
	]
};