export const agregerChiffres = (dictionnaire) => {
    let valeurTotale = 0
    for (let key in dictionnaire) {
        valeurTotale += dictionnaire[key]
    }
    return valeurTotale
}

export default {
    agregerChiffres
}
