module.exports = {
	"title": "Hi, World!",
	"description": "This is the description of the hello world post.",
	"content": [
		{
			"type": "text",
			"value": "# Principe général"
		},
		{
			"type": "text",
			"value": "Cette plateforme a deux objectifs :"
		},
		{
			"type": "list",
			"value": [
				"présenter différents facteurs de risque de précarité alimentaire, leurs indicateurs et leur importance dans un territoire donné ;<br>",
				"produire un indice global de risque de précarité alimentaire qui rende compte du cumul de facteurs de risque à l’échelle de communes ou d’ “îlots” urbains (IRIS), et en fournir une représentation cartographiée (cartographie départementale : risques de précarité alimentaire des différentes communes du département ; cartographie communale : risque de précarité alimentaire des différents IRIS de la commune)."
			]
		},
		{
			"type": "text",
			"value": "Cette page s’intéresse à la méthode de calcul de l’indice global. Celui-ci est construit à partir d’une dizaine d’indicateurs dont les valeurs à l’échelle communale et/ou IRIS sont librement accessibles en ligne et régulièrement actualisées (et majoritairement issues ou calculées à partir de données de l’Insee). Ces indicateurs reflètent quatre catégories de facteurs de de risques de précarité alimentaire identifiés grâce à la littérature : contraintes économiques, Isolement social, environnement alimentaire appauvri et difficulté d’accès à l’information alimentaire et nutritionnelle."
		},
		{
			"type": "text",
			"value": "Selon l’échelle envisagée, commune ou IRIS, les indicateurs utilisés ne sont pas exactement les mêmes, soit parce que certains indicateurs ne sont pas disponibles à l’une ou l’autre des échelles en raison du secret statistique (comme c’est le cas pour les indicateurs de contraintes économiques), soit par choix volontaire. Par exemple, les indicateurs concernant l’environnement alimentaire diffèrent pour rendre compte des risques de précarité alimentaire en milieu plutôt rural pour l’échelle communale, et en milieu urbain à l’échelle IRIS)."
		},
		{
			"type": "table",
			"value": [
				"| Catégories de risques                                                             | Indicateurs à l’échelle communale                                                                | Indicateurs à l’échelle IRIS                                                                     |",
				"| --------------------------------------------------------------------------------- | ------------------------------------------------------------------------------------------------ | ------------------------------------------------------------------------------------------------ |",
				"|Contraintes économiques                                                           | Médiane du revenu                                                                                | Taux de pauvreté au seuil de 60%                                                                 |",
				"|Isolement social                                                                  | Taux de chômage                                                                                  | Taux de chômage                                                                                  |",
				"|Isolement social                                                    | Part des ménages monoparentaux                                                                   |",
				"|Isolement social                                                  | Part des ménages de une personne                                                                 |",
				"|Isolement social                                                       | Part des jeunes non insérés ?                                                                    |",
				"|Isolement social                                                           | Part des plus de 75 ans                                                                          |",
				"|Environnement alimentaire appauvri                                                | Temps d’accès à la GMS la + proche                                                               | Score de densité de GMS                                                                          |",
				"|Environnement alimentaire appauvri                                                | Score de densité supérettes et épiceries                                                         |",
				"|Accès et/ou compréhension difficile à l’information alimentaire et nutritionnelle | Part des personnes non scolarisées de 15 ans ou plus titulaires au plus d’un brevet des collèges | Part des personnes non scolarisées de 15 ans ou plus titulaires au plus d’un brevet des collèges |",
				"|Accès et/ou compréhension difficile à l’information alimentaire et nutritionnelle | Score de densité de l’offre en conseils en alimentation-santé et en nutrition                    |"
			]
		},
		{
			"type": "text",
			"value": "Ces différents indicateurs sont de natures diverses : une médiane de revenus mesurée en euros, un taux de chômage mesuré en pourcentages, un temps d’accès à la Grande et Moyenne Surface (GMS) la plus proche mesuré en minutes… Afin d’obtenir une valeur qui combine ces différents indicateurs, on calcule un score (entre 1 et 5) reflétant pour une commune ou un IRIS donné sa « situation relative ».  Ce calcul se fait en quatre étapes :"
		},
		{
			"type": "list",
			"value": [
				"1/ Choisir une échelle de référence pour la comparaison locale, ou nationale."
			]
		},
		{
			"type": "table",
			"value": [
				"| Echelle considérée> Niveau de comparaison v | Si on s’intéresse à une commune                                                                                                                                                                 | Si on s’intéresse à un IRIS                                                                                                                                                               |",
				"| --------------------------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- | ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |",
				"| Comparaison départementale                    | L’échelle de référence est le département : on compare la valeur de l’indicateur pour la commune choisie aux valeurs prises par cet indicateur dans l’ensemble des communes du même département | L’échelle de référence est la commune : on compare la valeur de l’indicateur pour l’IRIS choisi aux valeurs prises par cet indicateur dans l’ensemble de tous les IRIS de la même commune |",
				"| Comparaison nationale                         | L’échelle de référence est le pays : on compare la valeur de l’indicateur pour la commune choisie aux valeurs prises par cet indicateur dans l’ensemble  des communes de France                 | L’échelle de référence est le pays : on compare la valeur de l’indicateur pour l’IRIS choisi aux valeurs prises par cet indicateur dans l’ensemble de tous les IRIS de France             |"
			]
		},
		{
			"type": "list",
			"value": [
				"2/ Standardiser les indicateurs par rapport à cette échelle de référence : pour une commune ou un IRIS donné, chaque indicateur reçoit un score d’une valeur entière comprise entre 1 et 5, 5 caractérisant les situations où le risque de précarité alimentaire est le plus élevé (par exemples : revenus faible, taux de chômage élevé, grande distance à la GMS la plus proche). Le score est calculé selon une méthode de discrétisation de données que l’on appelle la méthode « des seuils naturels ». Elle permet, grâce à l’utilisation de l’algorithme dit « de Jenks », de créer des classes homogènes (ici 5 classes) en regroupant entre elles les valeurs similaires d’une série de données (concernant soit toutes les communes d’un département ou les IRIS de la commune, soit toutes les communes ou les IRIS de France, selon l’échelle choisie) et en maximisant les différences entre les classes.",
				"3/ Produire un indice par catégorie de facteurs de risque (contraintes économiques, Isolement social, etc.) en faisant la moyenne des scores des différents indicateurs pour une catégorie de risque. Ainsi, pour la catégorie « Isolement social », la valeur de l’indice correspond à la moyenne des scores des cinq indicateurs de cette catégorie. Cet indice par catégorie a lui aussi une valeur comprise entre 1 et 5. L’outil permet de générer des cartes spécifiques à chaque catégorie de risque contrastant les zones selon leur score : a) comparant les communes à l’échelle nationale ; b) les comparant à l’échelle de leur département ; c) comparant les IRIS à l’échelle nationale ; d) les comparant à l’échelle de leur commune.",
				"4/ Faire une moyenne pondérée des indices des quatre catégories de facteurs de risque, en utilisant les pondérations suivantes : un coefficient de 2 à l’indice de l’axe « contraintes économiques » (représentant donc 40% de l’indice synthétique) et un coefficient de 1 à l’indice de chacune des trois autres catégories (représentant donc chacun 20% de l’indice synthétique). Ce choix de pondération reflète l’importance des contraintes économiques dans les situations de précarité alimentaire. On obtient alors pour chaque commune ou IRIS un indice synthétique de risque de précarité alimentaire dont la valeur est à nouveau comprise entre 1 et 5. L’outil permet de générer des cartes de cet indice synthétique, là encore soit en comparaison à l’échelle nationale, soit aux échelles du département (pour les communes) ou de la commune (pour les IRIS)."
			]
		}
	]
};