import { createRouter, createWebHistory } from 'vue-router'

import Territoires from '@/components/pages/SISTAHome.vue'
import PNRHome from '@/components/pages/PNRHome.vue'
import VobsalimHome from '@/components/pages/VobsalimHome.vue'
import DepartementView from '@/components/vobsalim/DepartementView.vue'
import MethodologieVue from '@/components/vobsalim/MethodologieVue.vue'
import IndicateursView from '@/components/vobsalim/IndicateursView.vue'
import AProposView from '@/components/vobsalim/APropos.vue'
import PNRSista from '@/components/PNR/PNRSista.vue'

const routes = [
  { path: '/', component: Territoires },
  { path: '/:project', component: Territoires },
  { path: '/PNR', component: PNRHome },
  {
    path: '/pnr/sista',
    name: 'PNR Sista',
    component: PNRSista,
  },
  { path: '/obsoalim', component: VobsalimHome },
  {
    path: '/obsoalim/departement/:code',
    name: 'departement',
    component: DepartementView,
    props: true,
  },
  {
    path: '/obsoalim/methodologie',
    name: 'methodologie',
    component: MethodologieVue,
  },
  {
    path: '/obsoalim/indicateurs',
    name: 'indicateurs',
    component: IndicateursView,
  },
  {
    path: '/obsoalim/a-propos',
    name: 'a-propos',
    component: AProposView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
