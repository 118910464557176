<template>
  <div>
    <div v-if="errors != null">
      <p>{{ errors }}</p>
    </div>
    <div v-else-if="!data_loaded">
      <p v-if="loading_advancement != null">
        Loading data {{ loading_advancement.done }}/{{
          loading_advancement.total
        }}
      </p>
      <p v-else>Loading data</p>
      <button class="button is-warning is-loading">Loading</button>
    </div>
    <div v-else style="height: 100%; width: 100%">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingWrapper",
  props: {
    data_loaded: Boolean,
    errors: Object,
    loading_advancement: Object,
  },
  components: {},
  data() {
    return {
      selected_currency: null,
    };
  },
  mounted: function () {},
  computed: {},
  methods: {},
};
</script>
