<script setup>
import { RouterView } from "vue-router";
import NavBar from "@/components/NavBar.vue";
</script>

<template>
  <NavBar />
  <RouterView />
</template>

<script>
export default {
  name: "TerritoiresGeneriques",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,i,600,700,800,900');
#app {
  font-family: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #3e3e3e;
  /* margin-top: 60px; */
}
</style>
