<template>
  <div>
    <div class="blocAccordeon">
      <div class="blocAccordeon__titre" @click="toggle">
        <div class="blocAccordeon__titre__texte">
          <slot name="titre"></slot>
        </div>
        <div class="blocAccordeon__titre__icone">
          <svg
            class="blocAccordeon__titre__icone__svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              v-if="!ouvert"
              d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"
            />
            <path
              v-if="ouvert"
              d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
            />
          </svg>
        </div>
      </div>
      <div class="blocAccordeon__contenu" v-show="ouvert">
        <slot name="contenu"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blocAccordeon",
  data() {
    return {
      ouvert: false,
    };
  },
  methods: {
    toggle() {
      this.ouvert = !this.ouvert;
    },
  },
};
</script>

<style scoped>
.blocAccordeon {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 70%;
  margin: 16px;
}

.blocAccordeon__titre {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.blocAccordeon__titre__texte {
  font-size: 16px;
  font-weight: 600;
}

.blocAccordeon__titre__icone {
  align-items: center;
  display: flex;
}

.blocAccordeon__titre__icone__svg {
  fill: #000;
  height: 24px;
  width: 24px;
}

.blocAccordeon__contenu {
  padding: 20px;
}

.blocAccordeon__contenu p {
  margin-bottom: 20px;
}
</style>
