module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Présentation du facteur de risque <a class=\"anchor\" href=\"#prsentation-du-facteur-de-risque\"></a>"
		},
		{
			"type": "text",
			"value": "L’accès et la compréhension à l’information peuvent représenter une contrainte contribuant aux situations de précarité alimentaire. Il est vrai que des études montrent que les mangeurs en situation de précarité alimentaire sont globalement bien informés sur ce qu’est une bonne alimentation, et qu’ils peinent plutôt à y avoir accès pour des raisons économiques. A cet égard, nombre de messages de prévention sont vécus par les personnes concernées comme étant culpabilisants. Malgré tout, un faible accès à une offre de soin qui permettrait de recevoir des informations personnalisées sur sa situation ou un manque de repères nutritionnels de base, qui s’acquièrent notamment lors de l’enseignement scolaire, peuvent constituer des facteurs de risques de précarité alimentaire."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "list",
			"value": [
				"[Rey S., Leduc A., Debussche X., Rigal L., Ringa V., Costemalle  V. (2023) Une personne sur dix éprouve des difficultés de compréhension de l'information médicale. DREES. Etudes et résultats n° 1269](https://drees.solidarites-sante.gouv.fr/publications-communique-de-presse/etudes-et-resultats/une-personne-sur-dix-eprouve-des-difficultes)"
			]
		},
		{
			"type": "text",
			"value": "## Indicateurs choisis"
		},
		{
			"type": "text",
			"value": "### Part des personnes non scolarisées de 15 ans ou plus titulaires au plus d’un brevet des collèges (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "### Indicateur d’accessibilité potentielle localisée à l’offre de soins"
		},
		{
			"type": "Source",
			"value": "DREES"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus](https://drees.solidarites-sante.gouv.fr/sources-outils-et-enquetes/lindicateur-daccessibilite-potentielle-localisee-apl)"
		},
		{
			"type": "text",
			"value": "## Verbatims"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": "> *« On manque de moyens, des fois même d’informations, mais c’est un manque de moyens dans le sens où on n’est pas formés… »*"
		},
		{
			"type": "text",
			"value": "> *« Mais aussi être informé correctement, parce que, des fois, on a l’impression que c’est de la publicité, alors que ça a une relation avec la santé de chacun. »*"
		},
		{
			"type": "text",
			"value": "> *« Oui, l’alimentation, elle joue sur la santé : si tu ne manges pas les vitamines, les tout ça, ce dont a besoin notre corps, ce qui est indispensable, on va dire, pour notre bien-être. Sauf qu’on ne les connaît pas tous bien comme il faut. Donc on nous dit : “Tu ne dois pas manger tant de lipides, tant de glucides, tant de je sais pas quoi…” Pff, c’est quoi ça ? On nous envoie des trucs : attention il faut manger, il faut prendre des vitamines B, après il faut prendre des vitamines D, après il faut prendre des machins trucs… Enfin, au total, on ne sait pas ce qu’il faut vraiment… Alors, on fait au petit bonheur la chance avec ce qu’on a sous la main. Et bah, sous la main, c’est souvent des trucs bah qu’il faut pas manger en trop grande quantité parce qu’il y a trop de lipides, trop de… J’en sais rien. »*"
		},
		{
			"type": "text",
			"value": "> *« Même quand on est malade, les médecins, des fois, ils nous conseillent de nous changer notre alimentation. Ils savent que certains aliments vont favoriser le virus ou la bactérie, donc ils nous disent d’ôter certains et on transforme notre alimentation et souvent ils nous guident vers du fait maison. Parce que dans l’industriel, il y a trop de choses qui sont inconnues pour le consommateur mais que si on est attentif, ben, au dernier… Quand on va à l’hôpital, il y a toujours des petits, des fois, des petits tracts. Des fois, c’est écrit en petit en salle d’attente. Ben c’est comme ça que je m’informe. Voilà. »*"
		},
		{
			"type": "text",
			"value": "> *« Oui, un atelier cuisine comme ils avaient fait dans le passé pourrait être bénéfique pour ceux qui savent pas trop se nourrir, qui ont pas appris ce que c’est d’équilibrer telle et telle chose, ou pour ceux qui n’ont pas les moyens. »*"
		},
		{
			"type": "text",
			"value": "> *« La diététique, c’est important quand même. Parce qu’on parle beaucoup de diabète dans les  maladies chroniques, c’est sûr que la diététique, c’est important. Alors c’est bien quand il y a des actions d’éducation qui sont mises en place. »*"
		}
	]
};