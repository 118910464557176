module.exports = {
	"content": [
		{
			"type": "text",
			"value": "# A propos"
		},
		{
			"type": "text",
			"value": "Cet outil a été développé dans le cadre du projet OBSOALIM34 (Observatoire des Solidarités Alimentaires de l’Hérault), initié en 2020 avec le soutien du Plan de Relance et du Conseil Départemental de l’Hérault. Il est co-porté par la Chaire Unesco Alimentations du monde de l'Institut Agro Montpellier et du Cirad et par la Fédération des Acteurs de la Solidarité Occitanie."
		},
		{
			"type": "text",
			"value": "L’un des objectifs du projet consistait à produire une méthodologie de caractérisation de la précarité alimentaire à l’échelle territoriale. En effet, il n’existe pas à ce jour en France d’enquête nationale portant sur le sujet : la précarité alimentaire est donc estimée à partir des chiffres communiqués par les associations d’aide alimentaire portant sur le volume de denrées distribuées ou sur le nombre de personnes bénéficiaires. Une telle approche de la précarité alimentaire est insatisfaisante à plusieurs égards :"
		},
		{
			"type": "list",
			"value": [
				"elle ne rend pas compte de l’ampleur réelle de la situation car elle n’intègre pas les situations de non-recours, relatives aux personnes en situation de précarité alimentaire qui ne se rendent pas à l’aide alimentaire (pour des raisons de honte, de difficultés d’accès, de manque d’informations, etc.)",
				"elle ne reflète pas la diversité des situations de précarité alimentaire car elle ne prend pas en compte les différents déterminants de cette précarité (économiques, sociaux, environnementaux, informationnels)",
				"les données remontées par les associations d’aide alimentaire ne sont pas basées sur les mêmes méthodes de comptage, et ne sont pas facilement accessibles aux échelles territoriales."
			]
		},
		{
			"type": "text",
			"value": "Le travail engagé dans le cadre de Obsoalim sur le diagnostic de la précarité alimentaire à l’échelle locale a été piloté par la Chaire Unesco Alimentations du monde, le Cirad, l’INRAE et la Métropole de Montpellier. Il avait pour but de fournir un état des lieux de la précarité alimentaire actualisable dans le temps et réplicable dans d’autres territoires que l’Hérault. Superposé à une cartographie des points de solidarités alimentaires, cet état des lieux permet d’identifier des zones blanches, c’est-à-dire des zones à risque non couvertes par des dispositifs de lutte contre la précarité alimentaire. Ce travail a donné lieu à [une première publication](https://www.chaireunesco-adm.com/No24-Une-approche-territoriale-des-facteurs-de-precarite-alimentaire-utilisant) et a nourri les réflexions menées dans le Groupe de Travail n°1 du Comité National de Coordination de la Lutte contre Précarité Alimentaire (COCOLUPA) en 2021 et 2022."
		},
		{
			"type": "text",
			"value": "Il repose sur une démarche en trois temps : 1/ repérage dans la littérature des grandes familles de facteurs de précarité alimentaire ; 2/ identification d’indicateurs dont les données sont régulièrement actualisées, disponibles à l’échelle communale  et/ou des IRIS et librement accessibles (pour répondre au besoin d’actualisation et de réplicabilité) ; 3/ production et amélioration continue d’une méthodologie de calcul d’un score rendant compte du cumul de conditions défavorables pouvant contribuer à la précarité alimentaire (voir l’onglet Méthodologie)."
		}
	]
};