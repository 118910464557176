var menuConfiguration = [
  {
    name: "Accueil",
    visible: true,
    menu: [
      {
        name: "",
        menu: [
          {
            name: "Accueil",
            rapportBI: {
              id: "7e58e34a-8524-4fa1-be54-e4d652264c0a",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/GENERAL%20-%20Guide,%20plan,%20p%C3%A9rim%C3%A8tre.pbix?csf=1&web=1&e=tH2rc5",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=7e58e34a-8524-4fa1-be54-e4d652264c0a&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection4e8bfa0fd160c44ecda0",
            },
          },
          {
            name: "Généralités",
            rapportBI: {
              id: "7e58e34a-8524-4fa1-be54-e4d652264c0a",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/GENERAL%20-%20Guide,%20plan,%20p%C3%A9rim%C3%A8tre.pbix?csf=1&web=1&e=tH2rc5",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=7e58e34a-8524-4fa1-be54-e4d652264c0a&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection",
            },
          },
          {
            name: "Typologie de territoires",
            rapportBI: {
              id: "5a87b4c3-da52-4310-a157-8a8ef81a7750",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/INTERNE-Typologie%20des%20territoires.pbix?csf=1&web=1&e=2LGJRH",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=5a87b4c3-da52-4310-a157-8a8ef81a7750&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSectionbae5cbe6629365cac244",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Production alimentaire",
    menu: [
      {
        name: "PRODUCTION AGRICOLE",
        menu: [
          {
            name: "Surfaces agricoles",
            rapportBI: {
              id: "d6d80987-39d1-453f-ba47-da886c5b8a3d",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/PRODUCTION-Agricole.pbix?csf=1&web=1&e=kXBAa0",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=d6d80987-39d1-453f-ba47-da886c5b8a3d&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection49ec70125f25efe54d99",
            },
          },
          {
            name: "Cheptels",
            rapportBI: {
              id: "60b96125-edd8-4b08-9a52-2f121e29e912",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/PRODUCTION-Recensement%20Agricole.pbix?csf=1&web=1&e=002W2X",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=60b96125-edd8-4b08-9a52-2f121e29e912&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection14e7d09c616045683293",
            },
          },
          {
            name: "Exploitations agricoles",
            rapportBI: {
              id: "60b96125-edd8-4b08-9a52-2f121e29e912",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/PRODUCTION-Recensement%20Agricole.pbix?csf=1&web=1&e=002W2X",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=60b96125-edd8-4b08-9a52-2f121e29e912&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSectionf65ee6df4d01568201ed",
            },
          },
          {
            name: "Démarches de valorisation",
            rapportBI: {
              id: "d6d80987-39d1-453f-ba47-da886c5b8a3d",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/PRODUCTION-Agricole.pbix?csf=1&web=1&e=kXBAa0",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=d6d80987-39d1-453f-ba47-da886c5b8a3d&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection4381dddaa80a57f0f8a8",
            },
          },
        ],
      },
      {
        name: "MILIEU DE CHAÎNE",
        explication:
          "Transformation alimentaire, commerce de gros et artisanat commercial",
        menu: [
          {
            name: "Milieu de chaîne",
            rapportBI: {
              id: "72fca6da-6a65-4944-a6b3-4584c8b86119",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/PRODUCTION-Milieu%20de%20chaine.pbix?csf=1&web=1&e=sQmc0S",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=72fca6da-6a65-4944-a6b3-4584c8b86119&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSectionc8f4dc128477c4c85e4e",
            },
          },
        ],
      },
      {
        name: "DURABILITÉ DE LA PRODUCTION",
        menu: [
          {
            name: "Problématiques de durabilité-production",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection50a89609453c6c37a685",
            },
          },
          {
            name: "Biodiversité : habitat (1)",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection16570ae548cacdd5554d",
            },
          },
          {
            name: "Biodiversité : habitat (2)",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection4fe930d8a549be7f9614",
            },
          },
          {
            name: "Biodiversité : exposition",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection2809d505effe72d5571e",
            },
          },
          {
            name: "Climat",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection791c9da13400e00ad24b",
            },
          },
          {
            name: "Eau",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection57054b6ce976c0a253d4",
            },
          },
          {
            name: "Santé des producteurs",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection51bf6ce06a81919aa1d9",
            },
          },
          {
            name: "Revenu décent et inégalités",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection96f6398a04e40702ea12",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Consommation alimentaire",
    menu: [
      {
        name: "CONSOMMATION",
        menu: [
          {
            name: "Profils des consommateurs",
            rapportBI: {
              id: "b96091ca-7d02-47bf-a039-6710c5ef81a1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/CONSOMMATION-Consommation.pbix?csf=1&web=1&e=fU8esS",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=b96091ca-7d02-47bf-a039-6710c5ef81a1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSectionfe53cca73a778be367b2",
            },
          },
          {
            name: "Consommation (estimations)",
            rapportBI: {
              id: "b96091ca-7d02-47bf-a039-6710c5ef81a1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/CONSOMMATION-Consommation.pbix?csf=1&web=1&e=fU8esS",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=b96091ca-7d02-47bf-a039-6710c5ef81a1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection44094dc653a9fc3cc317",
            },
          },
        ],
      },
      {
        name: "DISTRIBUTION",
        menu: [
          {
            name: "Distribution",
            rapportBI: {
              id: "5c3629e0-09b4-4363-b2d0-47b69becefd9",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/CONSOMMATION-Distribution.pbix?csf=1&web=1&e=sBEPmC",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=5c3629e0-09b4-4363-b2d0-47b69becefd9&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection1a210ca7d6fd9cb1df78",
            },
          },
          {
            name: "Restauration",
            rapportBI: {
              id: "5c3629e0-09b4-4363-b2d0-47b69becefd9",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/CONSOMMATION-Distribution.pbix?csf=1&web=1&e=sBEPmC",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=5c3629e0-09b4-4363-b2d0-47b69becefd9&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection1e79f6cef8a3c0810477",
            },
          },
        ],
      },
      {
        name: "DURABILITÉ DE LA CONSOMMATION",
        menu: [
          {
            name: "Problématiques de durabilité-consommation",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection1033eb98c5355a69a7a9",
            },
          },
          {
            name: "Impacts sur le territoire (Santé des consommateurs)",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection7610120ceec16834b79a",
            },
          },
          {
            name: "Impacts importés",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection7fb6cd8745e9910d5738",
            },
          },
        ],
      },
    ],
  },
  {
    name: "Durabilité et Résilience",
    menu: [
      {
        name: "LIENS PRODUCTION-CONSOMMATION",
        menu: [
          {
            name: "Potentiel nourricier",
            rapportBI: {
              id: "e1957913-31ae-4a7c-bc25-d52a1b4eda26",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Liens%20Prod-Conso.pbix?csf=1&web=1&e=SjTkAL",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=e1957913-31ae-4a7c-bc25-d52a1b4eda26&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSectionf6458db921620002cba0",
            },
          },
          {
            name: "Potentiel agro-industriel",
            rapportBI: {
              id: "e1957913-31ae-4a7c-bc25-d52a1b4eda26",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Liens%20Prod-Conso.pbix?csf=1&web=1&e=SjTkAL",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=e1957913-31ae-4a7c-bc25-d52a1b4eda26&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection3cdb1e7a75e59d379822",
            },
          },
        ],
      },
      {
        name: "PRINCIPALES THÉMATIQUES",
        menu: [
          {
            name: "Problématiques",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSectionff8878711a293a3a115a",
            },
          },
          {
            name: "Biodiversité : habitat (1)",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection16570ae548cacdd5554d",
            },
          },
          {
            name: "Biodiversité : habitat (2)",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection4fe930d8a549be7f9614",
            },
          },
          {
            name: "Biodiversité : exposition",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection2809d505effe72d5571e",
            },
          },
          {
            name: "Climat",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection791c9da13400e00ad24b",
            },
          },
          {
            name: "Eau",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection57054b6ce976c0a253d4",
            },
          },
          {
            name: "Revenu décent et inégalités",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection96f6398a04e40702ea12",
            },
          },
          {
            name: "Santé des producteurs",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19",
              page: "ReportSection51bf6ce06a81919aa1d9",
            },
          },
          {
            name: "Santé des consommateurs",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection7610120ceec16834b79a",
            },
          },
          {
            name: "Impacts importés",
            rapportBI: {
              id: "db71ea83-f9b9-48f9-8b99-e9a8b30648d1",
              lienSharePoint:
                "https://lebasic.sharepoint.com/:u:/r/sites/Basic_shared_files/Documents%20partages/1%20-%20STRATEGIE/2%20-%20Plans%20d%27action/Plan%20Action%20Territoires/Outil%20Transition%20Alimentaire%20des%20Territoires/SISTA%20-%20Fichiers%20sources%20PBI/DURABILITE-Durabilit%C3%A9.pbix?csf=1&web=1&e=boNBuh",
              embedURL:
                "https://app.powerbi.com/reportEmbed?reportId=db71ea83-f9b9-48f9-8b99-e9a8b30648d1&groupId=85121106-3378-4fee-9d1a-23a61e163e6f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUZSQU5DRS1DRU5UUkFMLUEtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d",
              page: "ReportSection7fb6cd8745e9910d5738",
            },
          },
        ],
      },
    ],
  },
];

var choix_territoires = {
  territoires_principaux: ["CA du Douaisis Agglo (200044618)"],
  territoires_comparaison: [
    "Région Hauts-de-France",
    "Département Nord (59)",
    "France métropolitaine",
  ],
  principal_par_defaut: "CA du Douaisis Agglo (200044618)",
  comparaison_par_defaut: "France métropolitaine",
};

const slicer_principal_title = "Choix du territoire"; // nom du filtre dans le rapport powerbi
const slicer_comparison_title = "Territoire de comparaison";

// obligatoire pour observatoire des PNR
const slicer_comparison_title_pnr = "Territoire de comparaison PNR";
const slicer_communes = "Choix de vos communes";
const slicer_aires_parc_pnx = "Aires du Parc";

export default {
  menuConfiguration,
  choix_territoires,
  slicer_principal_title,
  slicer_comparison_title,
  slicer_comparison_title_pnr,
  slicer_communes,
  slicer_aires_parc_pnx,
};
