module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Présentation du facteur de risque"
		},
		{
			"type": "text",
			"value": "Si les études existantes n’ont pas permis de mettre en évidence de lien de causalité direct entre l’environnement alimentaire auquel sont exposés les habitants et leurs pratiques d’approvisionnement, le temps d’accès aux commerces alimentaires apparaît comme un critère discriminant, lorsqu’il vient s’ajouter à d’autres facteurs de vulnérabilité, notamment en matière de mobilité. Pour des personnes éprouvant de fortes contraintes de déplacement, pour des raisons qui peuvent être diverses (incapacités physiques, non-motorisation, coût des déplacements véhiculés), la distance aux commerces peut être un frein majeur d’accès à l’alimentation. Les infrastructures de mobilité urbaines participent ainsi à cette accessibilité. En parallèle, la faible diversité des lieux d’approvisionnement alimentaires accentue cette vulnérabilité."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "text",
			"value": "Vonthron, S., (2021), Offre commerciale alimentaire et pratiques spatiales d’approvisionnement des ménages. Construire une géographie des paysages alimentaires (Thèse de doctorat en géographie), Université Paul Valéry Montpellier 3."
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisées"
		},
		{
			"type": "text",
			"value": "### Temps d’accès par la route vers la grande surface la plus proche (minute)"
		},
		{
			"type": "Source",
			"value": "jeu de données construit à partir de la BPE (Insee), mis à disposition sur data.gouv.fr"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "### Taux de non-motorisation des ménages (%)"
		},
		{
			"type": "Source",
			"value": "jeu de données construit à partir de celles de l’indicateur « Part des ménages disposant d'au moins une voiture », Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "### Verbatims"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« – T’imagines, M., où elle est, y a un bus le matin, un bus le midi, un bus le soir. Et quand il n’y a pas d’école, y a pas de bus. Parce que c’est le bus scolaire.<br>– Ça, c’est la campagne.<br>– Et ils sont à 7 km du premier magasin, ils vont à pied.<br>– Ils vont à pied ? 7 km ?<br>– Moi, j’ai la bagnole, donc quand j’y vais, ça fait moins loin pour elle que pour moi. Parce qu’elle habite dans le village d’après, mais tu vois, les gens avec leurs caddies sur le bord de la route. Avec leur charrette. »*"
		},
		{
			"type": "text",
			"value": ">*« C’est intéressant de voir que, par exemple, quand t’as trois machins comme ça, déjà comment t’y vas, eh bien il faut y aller en bus. Tu ne peux pas y aller, il faut y aller en voiture, tu calcules ton prix d’essence, tu calcules machin, au total, il vaut mieux aller à côté de chez toi et puis prendre ce qu’il y a… »*"
		},
		{
			"type": "text",
			"value": ">*« Moi je ne peux pas, je suis à pied, je vais mettre mon caddie, et je n’achète jamais tout, parce que je ne peux pas porter. On est bien d’accord, donc ça fait que j’y retourne, pour acheter les restes. »*"
		},
		{
			"type": "text",
			"value": ">*« Les jardins partagés, mais il faut qu’ils soient à proximité parce que ça, c’est souvent en dehors des agglomérations, donc il faut avoir un moyen de transport ou prendre les bus, faut avoir la possibilité. »*"
		}
	]
};