<template>
  <nav
    v-if="menu_items.length > 0"
    class="navbar is-transparent navbar-layout"
    :class="classDynamique"
    role="navigation"
    aria-label="main navigation"
  >
    <div v-if="checkIfObosalim" class="navbar-brand obsoalim-logo">
      <router-link :to="logoLink" class="logo">
        <img
          src="@/assets/obsoalim/sponsors/OBSOALIM.png"
          alt="logo du BASIC"
        />
        <p class="titre">Diagnostic territorial de la précarité alimentaire</p>
      </router-link>
    </div>
    <div v-else>
      <div class="navbar-brand">
        <router-link :to="logoLink" class="logo">
          <img src="@/assets/BASIC_logo.svg" alt="logo du BASIC" />
        </router-link>
      </div>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navMenu"
        @click="openHamburgerMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <i class="fas fa-bars burger-size"></i>
      </a>
    </div>
    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': isHamburgerOpen }"
    >
      <div class="navbar-end">
        <router-link
          v-for="item in menu_items"
          v-bind:key="item.path"
          class="navbar-item"
          :to="item.path"
          >{{ item.libelle }}</router-link
        >
        <a class="contact" href="mailto:marie.walser@supagro.fr"
          >NOUS CONTACTER</a
        >
      </div>
    </div>
  </nav>
</template>
<style scoped>
.navbar {
  height: 83px;
}

.navbar-brand {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.logo {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: inherit;
  padding: 5px;
  height: 100%;
}
.logo img {
  margin-right: 1rem;
}
</style>

<script>
export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      context: "",
      isHamburgerOpen: false,
      logoLink: "/",
      classDynamique: "",
    };
  },
  methods: {
    openHamburgerMenu() {
      this.isHamburgerOpen = !this.isHamburgerOpen;
    },
  },
  mounted: function () {
    console.log("window.location", window.location);
    this.context = this.$route.query.context;
    if (this.$route.path.includes("obsoalim")) {
      this.logoLink = "/obsoalim";
      this.classDynamique = "obsoalim";
    } else {
      console.log(this.$route.path.href);
    }
  },
  computed: {
    menu_items: function () {
      if (this.$route.path.includes("obsoalim")) {
        return [
          {
            libelle: "ACCUEIL",
            path: "/obsoalim",
          },
          {
            libelle: "METHODOLOGIE",
            path: "/obsoalim/methodologie",
          },
          {
            libelle: "PRESENTATION",
            path: "/obsoalim/indicateurs?facteur=synthétique",
          },
          {
            libelle: "A PROPOS",
            path: "/obsoalim/a-propos",
          },
        ];
      }
      return [];
    },
    checkIfObosalim: function () {
      return this.$route.path.includes("obsoalim");
    },
  },
};
</script>

<style scoped>
.obsoalim {
  height: 60px;
  border-bottom: 5px solid #f9b000;
  background: #006666;
  text-transform: uppercase;
  display: flex;
}

.obsoalim #navMenu {
  height: 100%;
}
.obsoalim #navMenu a {
  color: #d9eaeb;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
}

.navbar-end {
  padding-right: 32px;
}
.obsoalim .navbar-end a.router-link-exact-active {
  background: rgba(166, 198, 212, 0.61);
}

.obsoalim .navbar-brand img {
  filter: brightness(0) invert(1);
  max-height: 80%;
  display: flex;
}

.obsoalim .contact {
  display: flex;
  padding: 2px 16px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  border: 1px solid var(--bg, #f3f7f8);
  color: #d9eaeb;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  margin: auto;
  margin-left: 16px;
}

.obsoalim #navMenu a.contact:hover {
  background: white;
  color: #0a3e55;
}
.titre {
  color: #d9eaeb;
  font-weight: 600;
}

@media screen and (max-width: 1248px) {
  .titre {
    font-size: 14px;
  }
}
</style>
