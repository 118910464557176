<template>
  <p class="" id="logos">
    <img
      class="logo"
      src="../../assets/BASIC_logo.svg"
      alt="logo du BASIC"
    />
  </p>
</template>

<script>
export default {
  name: "HeaderAriege",
};
</script>


<style scoped>
  #logos {
    text-align: right;
    width: calc(100% - 430px);
    padding: 5px;
    height: 100px;
  }
  .logo {
    /* float: right; */
    height: 100px;
    /* width: 300px; */
    /* display: inline; */
    padding-left: 2rem;
    vertical-align: middle;

  }
  </style>
  