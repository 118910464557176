<template>
  <LoadingWrapper :data_loaded="!loading" style="height: 100%; width: 100%">
    <l-map
      ref="map"
      v-if="!loading"
      :zoom="zoom"
      :center="center"
      style="height: 100%; width: 100%"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-geo-json
        v-if="geojson"
        @ready="trouverCentre"
        :geojson="geojson"
        :options="options"
        :options-style="optionStyle"
      />
      <carteLegende></carteLegende>
    </l-map>
  </LoadingWrapper>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";
import LoadingWrapper from "@/components/widgets/LoadingWrapper.vue";
import basic_api from "@/repositories/basic_api.js";
import carteLegende from "./carteLegende.vue";

export default {
  name: "carteChloropeth",
  props: {
    code_du_territoire: String,
  },
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    carteLegende,
    LoadingWrapper,
  },
  data() {
    return {
      zoom: 9,
      enableTooltip: true,

      optionStyle: (feature) => ({
        weight: 0.5,
        color: "#ECEFF1",
        opacity: 1,
        fill: "url(img/tiny-hatch.svg)",
        fillColor: this.choisirCouleur(
          feature.properties.part_sau_dans_superficie,
          [1, 0.8, 0.6, 0.4, 0.2],
          ["#006d2c", "#2ca25f", "#66c2a4", "#b2e2e2", "#edf8fb"]
        ),
        fillOpacity: 1,
      }), // à développer des tranches / des quintiles typiquement pour le chomage => passer aussi potentiellement les couleurs dans le composants
      loading: false,
      center: null,
      geojson: null,
      fillColor: "#e4ce7f",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  watch: {
    code_du_territoire: async function (newValue, oldValue) {
      console.log(`code_du_territoire changed from ${oldValue} to ${newValue}`);
      this.refreshMap();
    },
  },
  methods: {
    choisirCouleur: function (valeur, bornesMax, couleurs) {
      if (isNaN(valeur)) {
        return "#FF3333";
      }
      var index = 0;
      while (valeur <= bornesMax[index]) {
        index++;
      }
      return couleurs[index - 1];
    },
    refreshMap: async function () {
      this.loading = true;
      var RPGresponse = await basic_api.getRPGDataForTerritory(
        this.code_du_territoire,
        2020,
        "commune"
      );
      var RGAResponse = await basic_api.getRGADataparCommunePourTerritoire(
        this.code_du_territoire,
        2020
      );
      var territoireResponse = await basic_api.getDataForTerritory(
        this.code_du_territoire,
        "commune"
      );
      var geojson_response = await basic_api.getGEOJSONForTerritory(
        this.code_du_territoire
      );
      console.log("geojson got", geojson_response);
      console.log("geojson got", territoireResponse);
      this.geojson = geojson_response[0]["geojson"];
      var RPGdata = RPGresponse["resultats"];
      var RGAdata = RGAResponse["resultats"];
      var territoireData = territoireResponse["resultats"];
      for (const i of this.geojson.features) {
        i.properties.part_sau_dans_superficie =
          RPGdata["Surface Agricole Utile"][i.properties.code_commune] /
          territoireData["Superficie totale du territoire"][
            i.properties.code_commune
          ];
        i.properties.otex_principale =
          RGAdata["OTEX principale"][i.properties.code_commune];
      }

      this.loading = false;
    },
    trouverCentre() {
      console.log("trouverCentre");
      const contour = L.geoJSON(this.geojson).getBounds();
      console.log(contour);
      this.$refs.map.leafletObject.fitBounds(contour);
    },
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
          "<div>Nom Commune:" +
            feature.properties.libelle_commune +
            "</br>" +
            "Part de la SAU:" +
            Intl.NumberFormat("fr-FR", {
              style: "percent",
              minimumFractionDigits: 0,
            }).format(feature.properties.part_sau_dans_superficie) +
            "</br>" +
            "Part de la SAU:" +
            feature.properties.otex_principale +
            "</div>",
          { permanent: false, sticky: true }
        );
      };
    },
  },
  async created() {
    await this.refreshMap();
  },
};
</script>

<style scoped>
.leaflet-container {
  display: flex;
}
</style>
