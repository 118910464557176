import menu_configuration from './menu_configuration.js'

const search_menu_item = (name, root_item) => {
  if (root_item.name == name) {
    return root_item
  }
  if (root_item.menu == undefined) {
    return null // Not found
  }
  for (var sub_item of root_item.menu) {
    var result = search_menu_item(name, sub_item)
    if (result != null) {
      return result
    }
  }
  return null // Not found
}

const get_menu_item_from_name = (name) => {
  for (var item of menu_configuration.menuConfiguration) {
    if (item.name == name) {
      return item
    }
    for (var sub_item of item.menu) {
      var result = search_menu_item(name, sub_item)
      if (result != null) {
        return result
      }
    }
  }
  return null
}

export default {
  get_menu_item_from_name
}
