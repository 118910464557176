export function creerObjetPNFiltre(listePN) {
  console.log(listePN);

  const result = listePN.reduce((acc, curr) => {
    const foundIndex = acc.findIndex(
      (item) => item.label === curr.value.slice(0, 3)
    );
    if (foundIndex !== -1) {
      acc[foundIndex].options.push(curr.label);
    } else {
      acc.push({
        label: curr.value.slice(0, 3),
        options: [curr.label],
      });
    }
    return acc;
  }, []);

  console.log("HELLO", JSON.stringify(result, null, 2));
  return result;
}

export function getPNIds(listePNNomChoisis, PNListes) {
  let codeTerritoireLists = PNListes.filter((pns) =>
    listePNNomChoisis.includes(pns.label)
  ).map((pnObject) => pnObject.value);
  console.log(codeTerritoireLists);
  return codeTerritoireLists;
}

export function getPNNoms(listePNIdChoisis, PNListes) {
  let codeTerritoireLists = PNListes.filter((pns) =>
    listePNIdChoisis.includes(pns.value)
  ).map((pnObject) => pnObject.label);
  return codeTerritoireLists;
}

export function getToutesCommunesParcs(listeParc, listeCommunes) {
  console.log("listeParc", listeParc);
  return listeCommunes
    .filter((communes) => listeParc.includes(communes.code_parc))
    .map((communeObject) => communeObject.code_territoire);
}

export const listeParcsUltraMarins = [
  "Parc national de la Guadeloupe",
  "Parc national de La Réunion",
  "Parc amazonien de Guyane",
  "PNR de Guyane",
  "PNR de Martinique",
];
