<template>
  <div>
    <div class="nvelle-entete">
      <h1><b>O</b>bservatoire des <b>P</b>arcs</h1>
      <div id="logo-container">
        <img
          v-if="codeImage"
          :src="require('@/assets/pnr/logo/' + codeImage + '.jpg')"
          class="logo-petit"
        />
      </div>
    </div>

    <div class="colonnes">
      <div class="colonne" id="colonne-contenu">
        <div class="colonne" id="territoire-principal">
          <h2>Choix du territoire</h2>
          <Multiselect
            v-model="territoires_selected"
            placeholder="Choisissez votre parc"
            :searchable="true"
            :loading="isLoading"
            :options="multiselectOptions"
            ref="multiselect"
          ></Multiselect>
        </div>
        <div v-if="PNRNames && territoires_selected">
          <PNRDashboard
            :nom_du_territoire="PNRNames.get(territoires_selected)"
            :code_du_territoire="territoires_selected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import PNRDashboard from "@/components/PNR/PRNDashboard.vue";

export default {
  name: "TerritoiresGeneriques",
  components: {
    Multiselect,
    PNRDashboard,
  },
  data() {
    return {
      territoires_selected: null,
      isLoading: true,
      multiselectOptions: null,
      PNRNames: null,
      codeImage: null,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px;
  margin: 1rem;
  max-width: 414px;
  flex: 1;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}
#en-tete {
  height: 85px;
  justify-content: flex-end;
}
#titre {
}
#logos {
  text-align: right;
  width: calc(100% - 430px);
  padding: 5px;
  height: 100px;
}
.logo {
  /* float: right; */
  height: 100px;
  /* width: 300px; */
  /* display: inline; */
  padding-left: 2rem;
  vertical-align: middle;
}
.logo-petit {
  max-height: 100%;
  max-width: 100%;
  padding-left: 0;
}
.colonnes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.colonne {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.colonne-et {
  display: flex;
  flex: 1;
}

#logo-container {
  width: 100px;
  height: 85px;
}

#colonne-navigation {
  background-color: rgb(248 248 248);
  flex: 1;
  height: calc(100vh - 122px);
  overflow: auto;
}
#colonne-contenu {
  flex: 4;
}
#navigation {
  padding: 1rem 0;
  text-align: left;
}
.liste-niveau-2 {
  max-height: 0px;
  transition: 0.3s;
  overflow: hidden;
}
.visible .liste-niveau-2 {
  max-height: 800px;
}
.element_de_liste {
  padding: 3px 1rem;
  width: 100%;
  display: inline-block;
  position: relative;
}
.fleche {
  position: absolute;
  left: calc(100% - 2rem);
  top: 3px;
  visibility: hidden;
  transition: 0.3s;
}
a.element_de_liste {
  color: inherit;
}
a.element_de_liste:not(.selected):hover {
  background-color: rgb(225, 225, 225);
}
.niveau1 {
  margin-bottom: 2rem;
}
.niveau1 > .element_de_liste {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.niveau1 > .element_de_liste:hover .fleche {
  visibility: visible;
}
.visible .fleche {
  visibility: visible;
  transform: rotate(90deg) translate(2px, 0px);
}

.niveau2 > .element_de_liste {
  /* font-size: 20px; */
  font-weight: 600;
  /* opacity: 0.6; */
  color: #636363;
  text-transform: uppercase;
}
.niveau2 {
  margin-bottom: 1rem;
}
.niveau3 {
  /* padding-left: 0.5rem; */
}
.selected {
  background-color: rgb(59, 59, 59);
  color: white !important;
}
.explication {
  border-radius: 20px;
  height: 22px;
  width: 22px;
  border: 2px solid #bebebe;
  position: relative;
  /* z-index: -2; */
  display: inline-block;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: #bebebe;
  top: -2px;
  /* border-style: inset; */
  box-shadow: inset 0 0 0px #000;
  margin-left: 6px;
  cursor: default;
}
.infobulle {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5px 8px;
  text-align: left;
  text-transform: none;
  font-weight: normal;
  border-radius: 0 6px 6px 6px;
  /* left: calc(100% + 15px); */
  top: 25px;
  color: white;
}
.explication:hover .infobulle {
  visibility: visible;
}

#choix-des-territoires {
  padding: 1rem;
  gap: 16px;
}
#territoire-principal {
  flex-grow: 2.6;
}
#territoire-principal .v-select {
  max-width: 600px;
}
#territoire-comparaison {
  padding: 0 1rem;
}
.chargement {
  position: absolute;
}
.v-select {
  max-height: 2.2rem;
}
#bouton-plein-ecran {
  position: absolute;
  top: 213px;
  right: 18px;
  background-color: white;
  text-decoration: underline;
  /* color: blue; */
  padding: 3px;
  cursor: pointer;
  user-select: none;
  z-index: 1;
}
.plein-ecran #bouton-plein-ecran {
  top: 0;
}

/* Icone de chargement */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nvelle-entete {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 85px;
}
</style>
