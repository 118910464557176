<template>
  <div v-if="archieData" class="container">
    <div id="indicateurs-list-container">
      <div class="indicateurs-list">
        <button
          v-for="facteur in facteurs"
          :key="facteur.nomFacteur"
          @click="changerIndicateurs(facteur.lienAncre)"
          :class="{ choisi: changerClass == facteur.lienAncre }"
        >
          {{ facteur.nomFacteur }}
        </button>
      </div>
    </div>
    <div class="content is-small">
      <template v-for="(contenu, index) in archieData.content" :key="index">
        <vue-showdown
          v-if="contenu.type == 'text'"
          :markdown="contenu.value"
        ></vue-showdown>
        <vue-showdown
          v-if="contenu.type == 'list'"
          :markdown="concateneList(contenu.value)"
        ></vue-showdown>
        <p v-if="['text', 'list'].includes(contenu.type)"></p>

        <span class="tag is-primary is-light" v-if="contenu.type == 'Source'">
          <vue-showdown :markdown="contenu.value"></vue-showdown>
        </span>
        <span class="tag is-info is-light" v-if="contenu.type == 'Tag'">
          <vue-showdown :markdown="contenu.value"></vue-showdown>
        </span>

        <article class="message is-link" v-if="contenu.type == 'Link'">
          <div class="message-body">
            <vue-showdown :markdown="contenu.value"></vue-showdown>
          </div>
        </article>
      </template>
    </div>
  </div>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import vobsalim_utils from "@/utils/vobsalim_utils.js";
import "archieml";
export default {
  name: "IndicateursVIew",
  components: [VueShowdown, "vue-showdown"],
  data() {
    return {
      archieData: null,
      indicateurChoisi: "monetaire",
      facteurs: null,
    };
  },
  mounted() {
    this.lireAncre();
    this.logArchieMlContenu();
    this.facteurs = vobsalim_utils.retournerFacteursObjets();
  },
  methods: {
    logArchieMlContenu() {
      console.log("=> Log contenu");
      const archiemlFichier = vobsalim_utils.retournerObjetparlienAncre(
        this.indicateurChoisi
      )[0].indicateurContenu;
      const archieData = require(`@/assets/obsoalim/indicateurs/${archiemlFichier}`);
      this.archieData = archieData;
      console.log(this.archieData);
      return this.archieData;
    },

    lireAncre() {
      this.indicateurChoisi = this.$route.query.facteur;
      console.log(this.indicateurChoisi);
    },

    concateneList(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "* " + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
    changerIndicateurs(indicateur) {
      this.indicateurChoisi = indicateur;
    },
  },
  computed: {
    changerClass() {
      console.log(this.indicateurChoisi);
      return this.indicateurChoisi;
    },
  },
  watch: {
    indicateurChoisi: ["logArchieMlContenu"],
  },
};
</script>

<style scoped>
.container {
  display: block;
  max-width: 1048px;
  padding: 32px 100px;
}
.container ul {
  list-style-type: decimal;
}

#indicateurs-list-container {
  display: flex;
  align-items: stretch;
}

.indicateurs-list {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 40px;
  padding: 32px;
  justify-content: center; /* center flex items vertically */
}

.indicateurs-list button {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0px;
  flex-direction: column;
  border: solid #f7f7f7 1px;
  background-color: white;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  letter-spacing: 0.3px;
}
.indicateurs-list button:hover {
  background-color: #f7f7f7;
  border: solid 1px #f7f7f7;
  color: #7a7291;
}
.indicateurs-list button.choisi {
  background-color: #e7f2ff;
  font-weight: bold;
  color: #1d3d63;
  letter-spacing: normal;
}
</style>
