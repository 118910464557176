<template>
  <div class="container">
    <div v-if="archieData" class="content is-medium">
      <template v-for="(contenu, index) in archieData.content" :key="index">
        <vue-showdown
          v-if="contenu.type == 'text'"
          :markdown="contenu.value"
        ></vue-showdown>
        <vue-showdown
          v-if="contenu.type == 'list'"
          :markdown="concateneList(contenu.value)"
        ></vue-showdown>
        <vue-showdown
          v-if="contenu.type == 'table'"
          tables="true"
          :markdown="concateneTable(contenu.value)"
          :options="{ tables: true }"
          :class="test"
        ></vue-showdown>

        <p></p>
      </template>
    </div>
  </div>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import "archieml";
import globalMethodologie from "@/assets/obsoalim/methodologie/global.archieml";

export default {
  name: "IndicateursVIew",
  components: [VueShowdown, "vue-showdown"],
  data() {
    return {
      archieData: null,
      indicateurChoisi: "monetaire",
      facteurs: null,
    };
  },
  mounted() {
    this.logArchieMlContenu();
  },
  methods: {
    logArchieMlContenu() {
      console.log("=> Log contenu");
      const archieData = globalMethodologie;
      this.archieData = archieData;
      console.log(this.archieData);
      return this.archieData;
    },
    concateneList(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "* " + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
    concateneTable(list) {
      var text = "";
      for (let i = 0; i < list.length; i++) {
        text += "" + list[i] + "\n";
      }
      console.log(text);

      return text;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 32px 0px;
}
.content {
  max-width: 1000px;
  margin: auto;
}
</style>
