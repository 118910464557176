module.exports = {
	"content": [
		{
			"type": "text",
			"value": "## Présentation du facteur de risque"
		},
		{
			"type": "text",
			"value": "L’alimentation, vectrice de partage et de convivialité, comporte une importante dimension sociale. Aussi, l’isolement et l’exclusion sociale peuvent mettre à mal le caractère hédoniste de l’alimentation et fragiliser l’équilibre des repas. Cela peut être le cas pour des personnes non-insérées socialement ou pour les sans-abris ; certaines situations familiales (veuvage, monoparentalité) peuvent également décourager la pratique culinaire et constituer un facteur d’appauvrissement alimentaire."
		},
		{
			"type": "text",
			"value": "## En savoir plus"
		},
		{
			"type": "list",
			"value": [
				"Ramel, M. & Boissonnat, H., (2018), Nourrir ou se nourrir. Renouveler le sens que l’on porte à l’acte alimentaire pour renouveler nos pratiques face à la précarité alimentaire, Forum, vol. 153, n°1, p. 53‑61.",
				"Cardon, P., (2009), « Manger » en vieillissant pose-t-il problème ? Veuvage et transformations de l’alimentation de personnes âgées, Lien social et Politiques, n°62, p. 85‑95.",
				"Lhuissier, A., (2006), Pauvreté, monoparentalité et alimentation: Une étude de cas dans le nord de la France, Cahiers de Nutrition et de Diététique, vol. 41, n°2, p. 104‑110.",
				"Plancade, A., (2014), Vivre dans la rue à Nice : Cuisine et récupération alimentaire, Éditions L’Harmattan, Paris, 150 p."
			]
		},
		{
			"type": "text",
			"value": "## Présentation des indicateurs utilisées"
		},
		{
			"type": "text",
			"value": "### Taux de chômage des 15-64 ans (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/taux-de-chomage-des-15-64-ans-rp)"
		},
		{
			"type": "text",
			"value": "### Part des ménages dont la famille principale est une famille monoparentale (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open Data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-menages-dont-la-famille-principale-est-une-famille-monoparentale)"
		},
		{
			"type": "text",
			"value": "### Part des ménages d’une seule personne (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-menages-dune-seule-personne)"
		},
		{
			"type": "text",
			"value": "### Taux de jeunes non insérés (ni en emploi, ni scolarisés – NEET) (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-jeunes-non-inseres-ni-en-emploi-ni-scolarises-neet)"
		},
		{
			"type": "text",
			"value": "### Part des 75 ans et plus (%)"
		},
		{
			"type": "Source",
			"value": "Insee, RP 2008-2013-2019"
		},
		{
			"type": "Tag",
			"value": "Open data"
		},
		{
			"type": "text",
			"value": "[En savoir plus sur l'indicateur](https://www.observatoire-des-territoires.gouv.fr/part-des-75-ans-et-plus)"
		},
		{
			"type": "text",
			"value": "## Verbatims"
		},
		{
			"type": "text",
			"value": "Verbatims tirés de l’ouvrage « Se nourrir lorsqu’on est pauvre » co-écrit par Magali Ramel, Huguette Boissonnat Pelsy, Chantal Sibué-De Caigny et Marie-France Zimmer paru aux éditions Quart Monde en 2016."
		},
		{
			"type": "text",
			"value": ">*« Pour le lien social et l’expérience de vie, quand on n’ose pas aller chez les gens parce qu’on peut rien apporter, tout simplement manger et qu’on ne peut pas inviter les gens et rendre la pareille, renvoyer l’ascenseur… Y’a ça aussi dans le lien social. […] Donc la nourriture peut créer du lien social comme ça peut séparer, éviter qu’on accepte d’aller chez des gens »*"
		},
		{
			"type": "text",
			"value": ">*« Si t’es tout seule, toute seule à la maison, moi je sais que je vais prendre du pain, hop une tranche de jambon, une feuille de salade et puis ça y est […] non, c’est pas équilibré mais ça va vite. »*"
		},
		{
			"type": "text",
			"value": ">*« Si j’ai pas le moral, je ne me fais pas à manger. »*"
		},
		{
			"type": "text",
			"value": ">*« Je sais que je me venge sur la nourriture. Quand ça va plus – tous ces problèmes, mon homme au chômage, ma vie qui part en vrille –, je me relève pour manger de la mayo. Ça me calme, ça calme mon angoisse. »*"
		},
		{
			"type": "text",
			"value": ">*« Moi, personnellement, je suis toute seule. Je prends beaucoup de légumes surgelés parce que c’est plus pratique pour moi. Et puis, comme ça, je peux m’en préparer que des petites parts. Alors que si j’achète des légumes frais, ben ce n’est pas à ma portée déjà, puis ils sont chers donc, ben, je vais en manger qu’une fois. »*"
		},
		{
			"type": "text",
			"value": ">*« Je voulais retrouver des relations, recevoir chez moi, trouver une place parmi les autres. »*"
		}
	]
};