<template>
  <div id="app-default">
    <div class="dashboard">
      <div class="titre">
        <div id="bienvenue">
          <p>
            Bienvenue dans le&nbsp;<span id="nom-parc">{{
              nom_du_territoire
            }}</span>
          </p>
        </div>
        <div class="titre-boutons">
          <router-link
            :to="{
              path: '/pnr/sista',
              query: { code_du_territoire: code_du_territoire },
            }"
          >
            <button class="bouton bouton-principal">
              Accéder au rapport complet
            </button>
          </router-link>
        </div>
      </div>
      <div class="info">
        <div class="chiffres-colonnes-container" v-if="donneespretes">
          <chiffreDetailVue
            v-for="nbClé in NombresClés()"
            v-bind:key="nbClé.indicateur"
            :indicateur="nbClé.indicateur"
            :chiffre="nbClé.chiffre"
            :image="nbClé.image"
            :description="nbClé.description"
            :source="nbClé.source"
            :format="nbClé.format"
          >
          </chiffreDetailVue>
        </div>
        <div class="colonne-carte-box">
          <div class="carte">
            <div class="titres-viz">
              <h3>Carte de la part de la SAU par commune (Source: RPG 2020)</h3>
            </div>
            <carteChloropethVue :code_du_territoire="code_du_territoire" />
          </div>
          <LoadingWrapper :data_loaded="topCultures != null">
            <div class="cartes-horizontal">
              <div class="titres-viz">
                <h3>
                  3 principaux usages agricoles du sol en milliers d'hectares
                  (RPG 2020)
                </h3>
              </div>
              <div class="viz-horizontale">
                <div
                  class="carte-carree"
                  v-for="culture in topCultures"
                  v-bind:key="culture"
                >
                  <div class="carte-carree-details">
                    <p class="carte-carre-chiffre">{{ culture["valeur"] }}</p>
                    <p class="carte-carree-label">{{ culture["culture"] }}</p>
                    <p class="carte-carree-label">
                      {{ culture["pourcentage"] }} de la SAU
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </LoadingWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingWrapper from "@/components/widgets/LoadingWrapper.vue";
import chiffreDetailVue from "@/components/visualisation/chiffreDetail.vue";
import carteChloropethVue from "@/components/visualisation/carteChloropeth.vue";
import basic_api from "@/repositories/basic_api.js";
import formatter_utils from "@/utils/formatter_utils.js";
import api_utils from "@/utils/api_utils.js";
import donnees_utils from "@/utils/donnees_utils.js";

export default {
  name: "PRNDashboard",
  props: {
    nom_du_territoire: String,
    code_du_territoire: String,
  },
  components: {
    chiffreDetailVue,
    carteChloropethVue,
    LoadingWrapper,
  },
  data() {
    return {
      RGAData2020: null,
      RGAData2010: null,
      RPGData: null,
      BasicData: null,
      territoiresData: null,
      topCultures: null,
    };
  },
  watch: {
    code_du_territoire: async function (newValue, oldValue) {
      console.log("code_du_territoire changed", oldValue, "=>", newValue);
      this.reset_data();
      this.get_data_for_territoire();
    },
  },
  beforeMount() {
    this.get_data_for_territoire();
  },
  methods: {
    reset_data: function () {
      this.topCultures = null;
      this.RPGData = null;
      this.RGAData2020 = null;
      this.RGAData2010 = null;
      this.BasicData = null;
      this.territoiresData = null;
    },
    get_data_for_territoire: async function () {
      this.RGAData2020 = await basic_api.getRGADataForTerritory(
        this.code_du_territoire,
        2020,
        "otex"
      );
      this.RGAData2010 = await basic_api.getRGADataForTerritory(
        this.code_du_territoire,
        2010,
        "otex"
      );
      this.RPGData = await basic_api.getRPGDataForTerritory(
        this.code_du_territoire
      );
      this.BasicData = await basic_api.getBasicDataForTerritory(
        this.code_du_territoire
      );
      this.territoiresData = await basic_api.getDataForTerritory(
        this.code_du_territoire
      );
      this.RPGDataParCulture = await basic_api.getRPGDataForTerritory(
        this.code_du_territoire,
        2020,
        null,
        1
      );
      this.Top3Cultures();
    },
    NombresClés: function () {
      let pointApiRPG = this.RPGData["resultats"];
      let pointApiRGA2020 = this.RGAData2020["resultats"];
      let pointApiRGA2010 = this.RGAData2010["resultats"];
      let pointApiBasic = this.BasicData["resultats"];
      let pointApiTerritoires = this.territoiresData["resultats"];
      var resultat = [
        {
          indicateur:
            "Nombre d'exploitations dont le siège est sur le territoire",
          chiffre: api_utils.agregerChiffres(
            pointApiRGA2020["Nombre d'Exploitations"]
          ),
          image: "noun-farm-419654.svg",
          description: `Soit une évolution de ${donnees_utils.evolution(
            api_utils.agregerChiffres(
              pointApiRGA2010["Nombre d'Exploitations"]
            ),
            api_utils.agregerChiffres(pointApiRGA2020["Nombre d'Exploitations"])
          )} depuis 2010`,
          source: this.RGAData2020["metadata"]["source"] + " 2020",
          format: "",
        },
        {
          indicateur: "Part de la Surface Agricole Utile dans le territoire",
          chiffre:
            pointApiRPG["Surface Agricole Utile"]["2020"] /
            pointApiTerritoires["Superficie totale du territoire"]["2018"],
          image: "BASIC_champs.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiTerritoires["Superficie totale du territoire"]["2018"]
          )} ha (superficie totale du parc)`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "Part de la Surface Agricole Utile en bio dans la SAU",
          chiffre:
            pointApiRPG["SAU bio en hectares"]["2020"] /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "BASIC_data-1.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["SAU bio en hectares"]["2020"]
          )} parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} hectares de SAU`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "Part de la Surface Toujours en Herbe dans la SAU",
          chiffre:
            pointApiRPG["SAU toujours en herbe en hectares"]["2020"] /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "BASIC_data-1.svg",
          description: `Soit ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["SAU toujours en herbe en hectares"]["2020"]
          )} de STH parmi ${formatter_utils.formatterNombreFormatFR(
            pointApiRPG["Surface Agricole Utile"]["2020"]
          )} ha de SAU`,
          source: this.RPGData["metadata"]["source"] + " 2020",
          format: "pourcentage",
        },
        {
          indicateur: "Mètres linéaires par ha de SAU",
          chiffre:
            (pointApiBasic["Mètre linéaires de haies"]["2020"] * 1000) /
            pointApiRPG["Surface Agricole Utile"]["2020"],
          image: "noun-trees-5382698.svg",
          description: `À comparer aux 110m de hais nécessaires, le seuil nécessire pour assurer la conservation de la biodiversité (source: AFAC)`,
          source: this.BasicData["metadata"]["source"] + " 2020",
          format: "",
        },
      ];
      return resultat;
    },
    Top3Cultures: function () {
      let resultat =
        this.RPGDataParCulture["resultats"]["Surface Agricole Utile"];
      console.log(resultat);
      var cultures = Object.keys(resultat)
        .map((culture) => {
          return [
            culture,
            resultat[culture],
            resultat[culture] /
              this.RPGData["resultats"]["Surface Agricole Utile"]["2020"],
          ];
        })
        .sort(function (itemA, itemB) {
          return itemB[1] - itemA[1];
        });
      console.log("sorted culture", cultures);
      this.topCultures = {};
      var taille_du_top = 3;
      this.topCultures = cultures.map((item) => {
        return {
          culture: item[0],
          valeur: formatter_utils.formatterNombreFormatFR(item[1] / 1000, 1),
          pourcentage: formatter_utils.formatPercent(item[2]),
        };
      });
      this.topCultures = this.topCultures.slice(0, taille_du_top);
    },
  },
  computed: {
    donneespretes: function () {
      return (
        this.RGAData2020 &&
        this.RGAData2010 &&
        this.RPGData &&
        this.BasicData &&
        this.territoiresData
      );
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://fonts.googleapis.com/css?family=Source Sans Pro");
#app-default {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 100vh;
  background: #f4f8fb;
}
.dashboard {
  display: flex;
  padding: 64px 0px;
  max-width: 1300px;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  font-family: "Source Sans Pro", sans-serif;
  background: #f4f8fb;
}

.titre {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  width: 100%;
}

.titre div {
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 60% */
  letter-spacing: 0.8px;
  display: flex;
}

.titre div#bienvenue {
  flex-grow: 1;
}

.titre span#nom-parc {
  justify-content: center;
  align-items: center;
  line-height: 24px; /* 60% */
  border-bottom: 6px solid var(--secondaire-secondaire, #27987c);
}

.info {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
}

.chiffres-colonnes-container {
  display: flex;
  width: 340px;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  gap: 10px;
  align-self: stretch;
  border: 1px solid var(--Brand-light, #c7dce4);
  background: var(--neutre-blanc, #fff);
  padding: 0px 16px;
  border-radius: 12px;
}

.chiffres-colonnes {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Brand-light, #c7dce4);
  background: var(--neutre-blanc, #fff);
}

.chiffres-colonnes-container div:last-child {
  border: none;
}

.indicateur-chiffre-colonne {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-bottom: 1px solid var(--Brand-light, #c7dce4);
}

.description {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 0.2px;
}

.colonne-carte-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  align-self: stretch;
  gap: 24px;
  flex: 1 0 0;
}

.carte {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Brand-light, #c7dce4);
  background: var(--neutre-blanc, #fff);
}

.cartes-horizontal {
  display: flex;
  height: 110px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid var(--Brand-light, #c7dce4);
  background: var(--neutre-blanc, #fff);
}
.titres-viz {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutre-neutre-20, #c8ccd1);
}

.carte-carree {
  display: flex;
  padding: 0px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-right: 1px solid var(--neutre-neutre-20, #c8ccd1);
}

.carte-carree-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.carte-carre-chiffre {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 75% */
  letter-spacing: 0.64px;
}

.carte-carree-label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 150% */
}

.viz-horizontale {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

/* A intégrer à un début de design system */

.bouton {
  border-radius: 0;
  border: 1px;
}
.bouton-principal {
  display: flex;
  height: 32px;
  padding: 16px 24px;
  align-items: center;
  background: var(--primaire-primaire, #0a3e55);
  color: var(--neutre-blanc, #fff);
  cursor: pointer;

  /* Texte/moyen/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.bouton-secondaire {
  display: flex;
  height: 32px;
  padding: 16px 24px;
  align-items: center;
  background: var(--primaire-primaire, white);
  border: 1px #0a3e55 solid;
  color: #0a3e55;
  cursor: pointer;

  /* Texte/moyen/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.titre-boutons {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.colonnes-chiffres-clés {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background-color: #0a3e55;
}
</style>
