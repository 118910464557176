<template>
  <div>
    <CoucheChargement v-if="!premier_chargement" />
    <div
      class="bouton-haut"
      id="bouton-rafraichir-cartes"
      @click="refresh_current_report()"
    >
      <span>rafraîchir les cartes</span>
    </div>
    <div
      v-for="report in powerbi_report_list"
      v-bind:key="report.id + report.page"
    >
      <div v-show="report.id + report.page == selected_report" class="">
        <DebugConsole
          v-if="false"
          :loaded_report="loaded_reports[report.id + report.page]"
          :report_config="report"
        ></DebugConsole>
        <div v-if="loading_step != null" class="chargement">
          {{ loading_step }}
        </div>
        <div
          v-bind:id="report.id + report.page"
          class="conteneur-rapport"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";
import basic_api from "@/repositories/basic_api.js";
import configuration from "@/utils/menu_configuration.js";
import CoucheChargement from "@/components/headers/CoucheChargement.vue";
import DebugConsole from "@/components/widgets/DebugConsole.vue";

export default {
  name: "PowerbiContainer",
  components: {
    DebugConsole,
    CoucheChargement,
  },
  props: {
    rapportBI: Object,
    territoires_principaux: Array,
    territoires_de_comparaison: Array,
    territoires_de_comparaison_pnr: Array,
    aires_du_parc_PNX_selected: String,
    villes_selected: Array,
    powerbi_report_list: Object,
  },
  data() {
    return {
      selected_report: "",
      loaded_reports: {},
      loading_step: null,
      premier_chargement: false,
    };
  },
  watch: {
    rapportBI: async function (newValue, oldValue) {
      console.log(oldValue, "=>", newValue);
      this.selected_report = this.rapportBI.id + this.rapportBI.page;
      this.load_powerbi_report();
    },
    territoires_principaux: async function (newValue, oldValue) {
      console.log(
        "territoires_principaux changed in PowerbiContainer:",
        oldValue,
        "=>",
        newValue
      );
      this.update_slicer_selection(
        configuration.slicer_principal_title,
        this.territoires_principaux
      );
    },
    territoires_de_comparaison: async function (newValue, oldValue) {
      console.log(oldValue, "territoires_de_comparaison=>", newValue);
      this.update_slicer_selection(
        configuration.slicer_comparison_title,
        this.territoires_de_comparaison
      );
    },
    territoires_de_comparaison_pnr: async function (newValue, oldValue) {
      console.log(oldValue, "territoires_de_comparaison=>", newValue);
      this.update_slicer_selection(
        configuration.slicer_comparison_title_pnr,
        this.territoires_de_comparaison_pnr,
        "regions"
      );
    },
    villes_selected: async function (newValue, oldValue) {
      console.log(oldValue, "villes_selected=>", newValue);
      this.update_slicer_selection(
        configuration.slicer_communes,
        this.villes_selected,
        "communes"
      );
    },
    aires_du_parc_PNX_selected: async function (newValue, oldValue) {
      console.log(oldValue, "aires_du_parc_PNX_selected=>", [newValue]);
      let aires_du_parc_PNX_selected = [newValue];
      if (newValue == "Tout le Parc") {
        aires_du_parc_PNX_selected = [
          "Aire d'adhésion",
          "Coeur de Parc",
          "Tout le Parc",
        ];
      }
      this.update_slicer_selection(
        configuration.slicer_aires_parc_pnx,
        aires_du_parc_PNX_selected,
        "aireparcspnx"
      );
      this.update_slicer_selection(
        configuration.slicer_aires_parc_pnx,
        [newValue],
        "aireparcspnxRGA"
      );
    },
  },
  mounted: function () {
    this.selected_report = this.rapportBI.id + this.rapportBI.page;
    this.load_powerbi_report();
  },
  methods: {
    refresh_current_report: async function () {
      this.loaded_reports[this.selected_report].powerbi_report.refresh();
    },
    load_powerbi_report: async function () {
      if (this.rapportBI.id + this.rapportBI.page in this.loaded_reports) {
        console.log("rapportBI already loaded:", this.rapportBI);
        return;
      }
      this.loading_step = "Sélection du rapport PowerBI";
      this.loaded_reports[this.rapportBI.id + this.rapportBI.page] = {};
      console.log("loading rapportBI:", this.rapportBI);
      const reportContainer = document.getElementById(
        this.rapportBI.id + this.rapportBI.page
      );
      // Use View permissions
      let permissions = models.Permissions.View;

      // Embed configuration used to describe the what and how to embed
      // This object is used when calling powerbi.embed
      // This also includes settings and options such as filters
      // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details
      var report_config = {
        type: "report",
        tokenType: models.TokenType.Aad, //models.TokenType.Embed,
        accessToken: basic_api.getToken(),
        embedUrl: this.rapportBI.embedURL,
        id: this.rapportBI.id,
        permissions: permissions,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      };

      let loadedResolve,
        reportLoaded = new Promise((res) => {
          loadedResolve = res;
        });
      let renderedResolve,
        reportRendered = new Promise((res) => {
          renderedResolve = res;
        });

      // Embed Power BI report when Access token and Embed URL are available
      try {
        console.log(
          "print powerbi to use it otherwise powerbi is not detected as used",
          powerbi
        );
        var powerbi_loaded_report = window["powerbi"].embed(
          reportContainer,
          report_config
        );
      } catch (errors) {
        console.log("Errors when loading report:", errors);
      }
      // report.off removes all event handlers for a specific event
      powerbi_loaded_report.off("loaded");

      // report.on will add an event handler
      powerbi_loaded_report.on("loaded", function () {
        loadedResolve();
        powerbi_loaded_report.off("loaded");
      });
      // report.off removes all event handlers for a specific event
      powerbi_loaded_report.off("rendered");

      // report.on will add an event handler
      powerbi_loaded_report.on("rendered", function () {
        renderedResolve();
        powerbi_loaded_report.off("rendered");
      });

      this.loading_step = "Chargement du rapport PowerBI";
      await reportLoaded;
      this.loading_step = "Personnalisation du rapport PowerBI";
      // Insert here the code you want to run after the report is loaded
      // Set active page
      await powerbi_loaded_report.setPage(this.rapportBI.page);
      this.loaded_reports[
        this.rapportBI.id + this.rapportBI.page
      ].powerbi_report = powerbi_loaded_report;

      // Set custom layout to hide some visuals
      var active_page = await powerbi_loaded_report.getActivePage();
      this.loaded_reports[
        this.rapportBI.id + this.rapportBI.page
      ].page_display_name = active_page.displayName;
      this.refresh_report_layout(active_page, powerbi_loaded_report);

      // Set 'territoire principal'
      this.update_slicer_selection(
        configuration.slicer_principal_title,
        this.territoires_principaux
      );

      // Set 'territoire de comparaison' to the selected value
      this.update_slicer_selection(
        configuration.slicer_comparison_title,
        this.territoires_de_comparaison
      );
      // Set 'villes_selected' to the selected value
      this.update_slicer_selection(
        configuration.slicer_communes,
        this.villes_selected,
        "communes"
      );
      this.update_slicer_selection(
        configuration.slicer_comparison_title_pnr,
        this.territoires_de_comparaison_pnr,
        "regions"
      );

      let aires_du_parc_PNX_selected = this.aires_du_parc_PNX_selected;
      if (aires_du_parc_PNX_selected == "Tout le Parc") {
        aires_du_parc_PNX_selected = [
          "Aire d'adhésion",
          "Coeur de Parc",
          "Tout le Parc",
        ];
      }
      this.update_slicer_selection(
        configuration.slicer_aires_parc_pnx,
        aires_du_parc_PNX_selected,
        "aireparcspnx"
      );
      this.update_slicer_selection(
        configuration.slicer_aires_parc_pnx,
        aires_du_parc_PNX_selected,
        "aireparcspnxRGA"
      );

      this.loading_step = "Affichage du rapport PowerBI";
      await reportRendered;
      // Insert here the code you want to run after the report is rendered
      this.loading = false;
      this.premier_chargement = true;
      this.refresh_current_report();
      this.loading_step = null;
    },
    // Fonction pas utilisée, mais je la laisse pour se rappeler que l'API powerbi permet de faire des trucs comme ça
    resize_active_page: async function (powerbi_loaded_report) {
      try {
        await powerbi_loaded_report.resizeActivePage(
          models.PageSizeType.Custom,
          1000,
          5000
        );
      } catch (errors) {
        console.log(
          "/!\\/!\\/!\\/!\\/!\\/!\\Errors while resizing page:",
          errors
        );
        this.loaded_reports[this.selected_report]["OtherErrors"] = errors;
      }
    },
    update_slicer_selection: async function (
      slicer_title,
      selection,
      slicerSchema = "basic"
    ) {
      console.log("updating slicer", slicer_title, " with value", selection);
      if (!selection) {
        return; // rien a faire si la sélection est nulle
      }
      var selection_sans_proxy = [...selection];
      if (
        this.loaded_reports[this.selected_report].powerbi_report == undefined
      ) {
        // Rapport par encore chargé, la mise à jour des slicer sera faite à la fin de son chargement
        return;
      }
      var activePage = await this.loaded_reports[
        this.selected_report
      ].powerbi_report.getActivePage();
      var slicer = await this.get_visual_by_title(slicer_title, activePage);
      this.customize_slicer_selection(
        slicer,
        selection_sans_proxy,
        slicerSchema
      );
      for (var id in this.loaded_reports) {
        if (id != this.selected_report) {
          activePage = await this.loaded_reports[
            this.selected_report
          ].powerbi_report.getActivePage();
          slicer = await this.get_visual_by_title(slicer_title, activePage);
          await this.customize_slicer_selection(
            slicer,
            selection_sans_proxy,
            slicerSchema
          );
        }
      }
    },
    customize_slicer_selection: async function (
      slicer,
      selection,
      slicerSchema = "basic"
    ) {
      if (slicer == null) {
        var errors = `Le slicer auquel on veut mettre la valeur '${selection}' introuvable?`;
        this.loaded_reports[this.selected_report]["setSlicerStateError"] =
          errors;
        console.log(
          "/!\\/!\\/!\\/!\\/!\\/!\\Errors when setting slicer state:",
          errors
        );
        return;
      }

      const basicFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "modele_tat territoires",
          column: "Territoire",
        },
        targets: [
          {
            table: "modele_tat territoires",
            column: "Territoire",
          },
        ],
        filterType: models.FilterType.Basic,
        operator: "In",
        values: selection,
        requireSingleSelection: false,
      };

      const communesFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "correspondances territoires__territoires_cartes",
          column: "code_territoire_donnees",
        },
        targets: [
          {
            table: "correspondances territoires__territoires_cartes",
            column: "code_territoire_donnees",
          },
        ],
        filterType: models.FilterType.Basic,
        operator: "In",
        values: selection,
        requireSingleSelection: false,
      };

      const regionsFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "territoires regions",
          column: "libelle_region",
        },
        targets: [
          {
            table: "territoires regions",
            column: "libelle_region",
          },
        ],
        filterType: models.FilterType.Basic,
        operator: "In",
        values: selection,
        requireSingleSelection: false,
      };
      const AireParcsPNXFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "correspondances territoires__territoires_cartes",
          column: "type_adhesion_filtre",
        },
        targets: [
          {
            table: "correspondances territoires__territoires_cartes",
            column: "type_adhesion_filtre",
          },
          {
            table: "modele_tat recensement_agricole",
            column: "type_adhesion_filtre",
          },
        ],
        filterType: models.FilterType.Basic,
        operator: "In",
        values: selection,
        requireSingleSelection: false,
      };
      const AireParcsPNXFilterRGA = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "modele_tat recensement_agricole",
          column: "type_adhesion_filtre",
        },
        targets: [
          {
            table: "modele_tat recensement_agricole",
            column: "type_adhesion_filtre",
          },
        ],
        filterType: models.FilterType.Basic,
        operator: "In",
        values: selection,
        requireSingleSelection: false,
      };
      try {
        // Set the slicer state/selected value.
        if (slicerSchema == "basic") {
          await slicer.setSlicerState({ filters: [basicFilter] });
        } else if (slicerSchema == "communes") {
          await slicer.setSlicerState({ filters: [communesFilter] });
        } else if (slicerSchema == "regions") {
          await slicer.setSlicerState({ filters: [regionsFilter] });
        } else if (slicerSchema == "aireparcspnx") {
          await slicer.setSlicerState({
            filters: [AireParcsPNXFilter],
          });
        } else if (slicerSchema == "aireparcspnxRGA") {
          await slicer.setSlicerState({
            filters: [AireParcsPNXFilterRGA],
          });
        } else {
          console.log("slicer type not recognized", slicer);
        }
      } catch (errors) {
        this.loaded_reports[this.selected_report]["setSlicerStateError"] =
          errors;
        console.log(
          "/!\\/!\\/!\\/!\\/!\\/!\\Errors when setting slicer state:",
          errors
        );
      }
      this.loaded_reports[this.selected_report]["slicer_state"] = null;
      this.loaded_reports[this.selected_report]["slicer_filters"] = null;
      this.loaded_reports[this.selected_report]["slicer_state"] =
        await slicer.getSlicerState();
      try {
        this.loaded_reports[this.selected_report]["slicer_filters"] =
          await slicer.getFilters();
      } catch (errors) {
        this.loaded_reports[this.selected_report]["slicer_filters"] =
          "errors getting slicer's filters" + errors;
        console.log(
          "/!\\/!\\/!\\/!\\/!\\/!\\Errors when getting slicer filter:",
          errors
        );
      }
    },
    get_visual_by_title: async function (title, activePage) {
      var visuals = await activePage.getVisuals();
      let relevant_visual = visuals.filter(function (visual) {
        return visual.title == title;
      })[0];

      return relevant_visual;
    },
    refresh_report_layout: async function (activePage, powerbi_loaded_report) {
      var powerbi_visuals = await activePage.getVisuals();
      var settings = {
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth,
          pagesLayout: {},
        },
      };
      var visualsLayout = {};
      var maxHeight = 0;
      var hiddenDisplayState = {
        mode: models.VisualContainerDisplayMode.Hidden,
      };
      for (var visual of powerbi_visuals) {
        if (maxHeight < visual.layout.y + visual.layout.height) {
          maxHeight = visual.layout.y + visual.layout.height;
        }
        if (visual.type == "pageNavigator") {
          console.log("hiding page navigator", visual);
        } else if (!visual.title) {
          console.log("visual.title is wrong", visual);
          console.log(visual.title.startsWith("(INTERNE)"));
        }
        if (visual.title == configuration.slicer_principal_title) {
          console.log(
            "hiding 'Choix du territoire' visual",
            visual.name,
            visual.layout
          );
          visualsLayout[visual.name] = {
            displayState: hiddenDisplayState,
            height: 100,
            width: 500,
            x: 0,
            y: 0,
            z: visual.layout.z,
          };
        } else if (visual.title == configuration.slicer_comparison_title) {
          console.log(
            "hiding 'territoire de comparaison' visual",
            visual.name,
            visual.layout
          );
          visualsLayout[visual.name] = {
            displayState: hiddenDisplayState,
            height: visual.layout.height,
            width: 200,
            x: visual.layout.x,
            y: visual.layout.y,
            z: visual.layout.z,
          };
        } else if (visual.title == configuration.slicer_comparison_title_pnr) {
          console.log(
            "hiding 'territoire de comparaison' visual",
            visual.name,
            visual.layout
          );
          visualsLayout[visual.name] = {
            displayState: hiddenDisplayState,
            height: visual.layout.height,
            width: 200,
            x: visual.layout.x,
            y: visual.layout.y,
            z: visual.layout.z,
          };
        } else if (visual.title == configuration.slicer_communes) {
          console.log(
            "hiding 'territoire de comparaison' visual",
            visual.name,
            visual.layout
          );
          visualsLayout[visual.name] = {
            displayState: hiddenDisplayState,
            height: visual.layout.height,
            width: 200,
            x: visual.layout.x,
            y: visual.layout.y,
            z: visual.layout.z,
          };
        } else if (visual.title == configuration.slicer_aires_parc_pnx) {
          console.log(
            "hiding 'aires du parc' visual",
            visual.name,
            visual.layout
          );
          visualsLayout[visual.name] = {
            displayState: hiddenDisplayState,
            height: visual.layout.height,
            width: 200,
            x: visual.layout.x,
            y: visual.layout.y,
            z: visual.layout.z,
          };
        } else if (visual.title.startsWith("(INTERNE)")) {
          console.log("hiding 'interne' visual", visual.name);
          visualsLayout[visual.name] = { displayState: hiddenDisplayState };
        } else {
          visualsLayout[visual.name] = visual.layout;
        }
      }
      settings.customLayout.pagesLayout[activePage.name] = {
        defaultLayout: {
          displayState: {
            mode: models.VisualContainerDisplayMode.Hidden,
          },
        },
        visualsLayout: visualsLayout,
      };
      await powerbi_loaded_report.updateSettings(settings);
    },
  },
};
</script>

<style scoped>
#bouton-rafraichir-cartes {
  position: absolute;
  top: 80px;
  right: 192px;
  background-color: white;
  /* text-decoration: underline; */
  color: #858585;
  padding: 9px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 0;
  box-shadow: 0px 11px 6px #00000014;
  border-radius: 0 0 5px 5px;
}
.conteneur-rapport {
  height: calc(100vh - 85px);
  /* border-top: 30px solid #f8f8f8; */
  /* border-bottom: 10px solid #f8f8f8; */
  padding: 0rem 0rem 0rem;
}

.plein-ecran .conteneur-rapport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.chargement {
  /* position: absolute; */
  margin-left: 1rem;
}
</style>
