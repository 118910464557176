<template>
  <div class="landing">
    <div v-show="chosenIndicator" style="">
      <div id="vizContainer" class="" style=""></div>
    </div>
    <div v-if="!chosenIndicator" class="container facteurs">
      <div class="retour-div">
        <router-link to="/obsoalim">
          <button class="retour">
            <img src="@/assets/icon/retour.svg" />Retour au choix du territoire
          </button>
        </router-link>
      </div>
      <div>
        <h1>{{ nomDepartement }}</h1>
        <h2>
          Explorez les facteurs de risque de précarité alimentaire du territoire
          choisi:<br />
        </h2>
        <p>Pour cela, cliquez sur l'une des catégories ci-dessous</p>
        <ul class="facteurs-risques">
          <li v-for="facteur in facteurs" :key="facteur.nomFacteur">
            <FacteursRisques
              :nomFacteur="facteur.nomFacteur"
              :lienAncre="facteur.lienAncre"
              :image="facteur.image"
              :code="codeDepartement"
              :key="$route.path"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import vobsalim_utils from "@/utils/vobsalim_utils.js";

import FacteursRisques from "@/components/vobsalim/FacteursRisques.vue";

export default {
  components: {
    FacteursRisques,
  },
  data() {
    return {
      chosenIndicator: null,
      nomDepartement: "",
      facteurs: [
        {
          nomFacteur: "Contraintes économiques",
          lienAncre: "monetaire",
          image: "BASIC_data-1.svg",
        },

        {
          nomFacteur: "Isolement social",
          lienAncre: "socio-eco",
          image: "BASIC_data-1.svg",
        },

        {
          nomFacteur: "Environnement alimentaire appauvri",
          lienAncre: "mobilite",
          image: "BASIC_data-1.svg",
        },

        {
          nomFacteur: "Information alimentaire insuffisante",
          lienAncre: "litteracie",
          image: "BASIC_data-1.svg",
        },

        {
          nomFacteur: "Approche combinée",
          lienAncre: "synthétique",
          image: "BASIC_data-1.svg",
        },
      ],
      viz: null,
      workbook: null,
      activeSheet: null,
    };
  },
  mounted() {
    this.codeDepartement = this.$route.params.code;
    console.log("changement d'url suite au chargement du composant");
    this.urlChanged(this.$route, this.$route);
    vobsalim_utils.getDepartementMap().then((response) => {
      this.nomDepartement = response.get(this.codeDepartement);
    });
  },
  computed: {},
  methods: {
    urlChanged(newRoute) {
      console.log("urlChanged", newRoute);
      this.chosenIndicator = null;
      if (newRoute.query && newRoute.path.includes("departement")) {
        this.chosenIndicator = newRoute.query.facteur;
      }
      if (this.chosenIndicator) {
        this.chargerTableau();
      }
    },
    onFilterSelection(filterEvent) {
      var viz = window.tableau.VizManager.getVizs()[0];
      filterEvent.getFilterAsync().then(function (filter) {
        let values = filter.getAppliedValues();
        //let values = filter.$9; // Also works but less reliable.
        console.log(values[0].value);
        try {
          var nouvelIndicateur = vobsalim_utils.retournerObjetparnom(
            values[0].value
          )[0].indicateurDefault;
          var worksheet = viz.getWorkbook().getActiveSheet();
          console.log("Async Filter Change" + nouvelIndicateur);
          return worksheet.applyFilterAsync(
            "court_label",
            nouvelIndicateur,
            tableau.FilterUpdateType.REPLACE
          );
        } catch (error) {
          console.log(error);
        }
      });
    },
    chargerTableau() {
      var viz = window.tableau.VizManager.getVizs()[0];

      if (viz) {
        viz.dispose();
        console.log("Précédente visualisation supprimée");
      }

      let tableauScript = document.createElement("script");
      tableauScript.setAttribute(
        "src",
        "https://public.tableau.com/javascripts/api/tableau-2.min.js"
      );
      var nomFacteur = vobsalim_utils.retournerObjetparlienAncre(
        this.$route.query.facteur
      )[0].nomFacteur;
      var indicateurDefault = vobsalim_utils.retournerObjetparlienAncre(
        this.$route.query.facteur
      )[0].indicateurDefault;

      console.log("Facteur nom:" + nomFacteur);
      console.log(indicateurDefault);
      document.head.appendChild(tableauScript);
      var containerDiv = document.getElementById("vizContainer"),
        url =
          "https://public.tableau.com/views/VulnrabilitAlim/Tableaudebord1?publish=yes",
        options = {
          Méthode: "jenks",
          code_departement: this.$route.params.code,
          device: "desktop",
          categorie_label: nomFacteur,
          court_label: indicateurDefault,

          onFirstInteractive: function () {
            var workbook = viz.getWorkbook();

            console.log(viz);
            console.log("work");
            console.log(workbook);
            var activeSheet = workbook.getActiveSheet();
            console.log(activeSheet);
          },
        };
      /*global tableau*/
      /*eslint no-undef: "error"*/

      viz = new tableau.Viz(containerDiv, url, options);

      viz.addEventListener(
        tableau.TableauEventName.FILTER_CHANGE,
        this.onFilterSelection
      );
    },
  },
  watch: {
    $route: ["urlChanged"],
  },
};
</script>

<style scoped>
h1 {
  font-size: 36px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
}

.landing {
  height: 100%;
}

.facteurs {
  background-color: #f5f5f5;
  padding: 0px 0px;
  text-align: center;
  display: grid;
  grid-template-rows: 60px auto;
}

#vizContainer {
  height: 90vh;
}

.facteurs-risques {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .facteurs-risques {
    flex-direction: column;
  }
}
.facteurs-risques {
  padding: 0px;
}
.facteurs-risques li {
  display: inline-flex;
  flex: 1 1 0px;
  padding: 34px 10px;
  margin: 10px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #f7f7f7;
  background: #ffffff;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.facteurs-risques li:hover {
  background-color: #f7f7f7;
}

.retour-div {
  text-align: left;
}

.retour-div button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: 2c3e50;
  cursor: pointer;
}
</style>
